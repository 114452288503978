import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { ClickAwayListener } from "@material-ui/core";
import { isEqual } from "lodash";

import { ExceedingPrice } from "../common/ExceedingPrice/exceedingPrice.js";
import { FirstSteps } from "../FirstSteps/FirstSteps";
import { KiDropdown } from "../common/Input/KiDropdown";
import { KiDatePicker } from "../common/KiDatePicker/KiDatePicker";
import { Translation } from "../common/Translation";
import { CustomButton } from "../common/Button/CustomButton.js";
import { useAuth } from "../../hooks/useAuth";
import { AccountContext } from "../../contexts/context.js";
import { DashboardKpiCards } from "./DashboardKpiCards.jsx";
import { StatusPicker } from "../pickers/StatusPicker.js";
import { DashboardTables } from "./DashboardTables.jsx";
import { UsecasePicker } from "./UsecasePicker.jsx";

import arrow from "../../public/arrow.png";

import classes from "./Dashboard.module.scss";
import { ShopifyImportLogoPopup } from "./popups/ShopifyImportLogoPopup.jsx";
import { ShopifySubscriptionPopup } from "./popups/ShopifySubscriptionPopup.jsx";

export const Dashboard = () => {

  const auth = useAuth();
  const intl = useIntl();
  const { account, config } = useContext(AccountContext);

  const [user, setUser] = useState();
  const [usecases, setUsecases] = useState([]);
  const [usecasesList, setUsecasesList] = useState([]);
  const [datePeriod, setDatePeriod] = useState(null);
  const [dayBegin, setDayBegin] = useState();
  const [dayEnd, setDayEnd] = useState();
  const [showParameters, setShowParameters] = useState(false);
  const [statusList, setStatusList] = useState();
  const [statusCheckedList, setStatusCheckedList] = useState();
  const [orderAttributionTime, setOrderAttributionTime] = useState(account.orderAttributionTime);
  const [forceReload, setForceReload] = useState(0);
  const [isParamModified, setIsParamModified] = useState(false);

  useEffect(() => {
    auth.getUser().then(setUser);
    auth.fetch("/api/usecase/getAll").then(result => {
      const usecases = result.usecases.map(usecase => ({
        name: usecase.name,
        category: usecase.category,
        checked: true,
      }));
      usecases.push({
        name: "newsletter",
        category: "newsletter",
        checked: true,
      });
      setUsecases(usecases);
      setUsecasesList(usecases);
    });

  }, [auth]);

  useEffect(() => {
    if (config && config.TurnoverFilteredByOrderStatus) {
      auth.fetch("/data/getDistinctOrderStatuses").then(setStatusList);
    }
  }, [auth, config]);

  useEffect(() => {
    if (statusList && !statusCheckedList && account) {
      if (account.orderStatusesFilterSaved.length >= 1 && account.orderStatusesFilterSaved[0] !== "NA") {
        setStatusCheckedList(account?.orderStatusesFilterSaved || []);
      } else {
        setStatusCheckedList([...statusList, "NA"]);
      }
    }
  }, [account, statusList, statusCheckedList]);

  useEffect(() => {

    let orderStatusesFilterSaved;

    if (statusCheckedList && (isParamModified || !isEqual(statusCheckedList, account?.orderStatusesFilterSaved || []))) {
      orderStatusesFilterSaved = statusCheckedList;
    }

    let _orderAttributionTime;

    if (orderAttributionTime && (isParamModified || orderAttributionTime !== account.orderAttributionTime)) {
      _orderAttributionTime = orderAttributionTime;
    }

    if (_orderAttributionTime || orderStatusesFilterSaved) {
      
      const timeout = setTimeout(() => {
        setIsParamModified(true);
        auth.fetch("/account_management/saveDashboardConfig", { 
          method: "POST",
          body: JSON.stringify({
            orderStatusesFilterSaved,
            orderAttributionTime: _orderAttributionTime
          })
        }).then(() => setForceReload(prev => prev + 1));
      }, 500);
      
      return () => clearTimeout(timeout);
    }
  }, [statusCheckedList, orderAttributionTime, account, auth, isParamModified]);

  const onUsecaseClick = key => {
    if (key === "all") {
      setUsecases(prev => {
        const checked = prev.filter(usecase => usecase.checked);
        return prev.map(usecase => ({ ...usecase, checked: checked.length === 0 }));
      });
    } else {
      setUsecases(prev => prev.map(usecase => usecase.name === key ? { ...usecase, checked: !usecase.checked } : usecase));
    }
  };

  const onUsecaseCategoryClick = category => {
    setUsecases(prev => {
      const checked = prev.filter(usecase => usecase.category === category && !usecase.checked);
      return prev.map(usecase => usecase.category === category ? {
        ...usecase,
        checked: !!checked.length,
      } : usecase);
    });
  };

  const onDatePeriodChange = useCallback(value => {
    const today = new Date();
    let dayBegin = new Date(account.date_creation);
    let dayEnd = today;
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);
    switch (value) {
      case "fromBeginning":
        dayBegin = new Date(account.date_creation); ;
        break;
      case "thisMonth":
        dayBegin = new Date(today.getFullYear(), today.getMonth(), 1); //first day of month
        break;
      case "previousMonth":
        dayBegin = new Date(today.getFullYear(), today.getMonth() - 1, 1); //first day of previous month
        dayEnd = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, -1); //last day of previous month
        break;
      case "interval":
        dayBegin = localStorage.filterDayBegin ? new Date(localStorage.filterDayBegin) : thirtyDaysAgo;
        dayEnd = localStorage.filterDayEnd ? new Date(localStorage.filterDayEnd) : today;
        break;
    }
    setDayBegin(dayBegin);
    setDayEnd(dayEnd);
    setDatePeriod(value);
  }, [account]);

  useEffect(() => {
    if (dayBegin) {
      localStorage.setItem("filterDayBegin", dayBegin);
    }
  }, [dayBegin]);

  useEffect(() => {
    if (dayEnd) {
      localStorage.setItem("filterDayEnd", dayEnd);
    }
  }, [dayEnd]);

  useEffect(() => {
    if (!datePeriod) {
      onDatePeriodChange("thisMonth");
    }
  }, [datePeriod, onDatePeriodChange]);

  const onButtonParametersClick = event => {
    event.stopPropagation();
    setShowParameters(prev => !prev);
  };

  const selectedUsecases = useMemo(() => usecases.filter(usecase => usecase.checked).map(usecase => usecase.name), [usecases]);

  return (
    <div className={classes.container}>
      <img src={arrow} alt="" className={classes.arrow} />
      <div className={classes.title}><span><Translation id="accountSetup.stepUser.welcome" /> {user?.attributes.given_name || ""}</span></div>

      <ExceedingPrice from="Home" />
      <FirstSteps />

      <div className={classes.options}>
        <div className={classes.optionsLeftPart}>
          <UsecasePicker usecases={usecases} onUsecaseClick={onUsecaseClick} onUsecaseCategoryClick={onUsecaseCategoryClick} />
          <div className={classes.datePeriodDropdownContainer}>
            <KiDropdown
              value={datePeriod}
              options={[
                { value: "fromBeginning", label: intl.messages["datePeriodList.fromBeginning"], },
                { value: "thisMonth", label: intl.messages["datePeriodList.thisMonth"], },
                { value: "previousMonth", label: intl.messages["datePeriodList.previousMonth"], },
                { value: "interval", label: intl.messages["datePeriodList.interval"], },
              ]}
              onChange={onDatePeriodChange}
              variant="table"
            />
          </div>
          {datePeriod === "interval" && 
            <>
              <div className={`${classes.intervalDateLabel} info`}><Translation id="home.statistics.from" /></div>
              <KiDatePicker
                value={dayBegin}
                onChange={setDayBegin}
              />
              <div className={`${classes.intervalDateLabel} info`}><Translation id="home.statistics.to" /></div>
              <KiDatePicker
                value={dayEnd}
                onChange={setDayEnd}
                minDate={dayBegin}
              />
            </>
          }
        </div>
        <CustomButton
          size="xs"
          type="secondary"
          onClick={onButtonParametersClick}
          style={showParameters ? { backgroundColor: "#484848" } : {}}
        >
          <div className={classes.paramsButtonInnerContainer}>
            <i className="fa-solid fa-gear" />
            <Translation id="dashboard.button.parameter" />
          </div>
        </CustomButton>

        <div className={classes.parametersPopupContainer} style={{ display: showParameters ? "block" : "none" }}>
          <ClickAwayListener onClickAway={() => setShowParameters(false)}>
            <div className={classes.parametersPopup}>
              {!!statusCheckedList && !!statusList &&
                <div className={classes.statusParameterContainer}>
                  <div className="text"><Translation id="dashboard.parameters.status.label" /></div>
                  <StatusPicker 
                    setStatusList={value => setStatusCheckedList(value)}
                    statusCheckedList={statusCheckedList}
                    statusList={statusList}
                    zIndex
                    marginTop={10}
                  />
                </div>
              } 
              <div className="text"><Translation id="dashboard.parameters.orderTime.label" /></div>
              <div className={classes.orderTimeDropdownContainer}>
                <KiDropdown 
                  value={orderAttributionTime}
                  options={[
                    { value: "4h", label: intl.messages["dashboard.parameters.orderTime.4h"], },
                    { value: "24h", label: intl.messages["dashboard.parameters.orderTime.24h"], },
                    { value: "5days", label: intl.messages["dashboard.parameters.orderTime.5days"], },
                    { value: "30days", label: intl.messages["dashboard.parameters.orderTime.30days"], },
                  ]}
                  onChange={setOrderAttributionTime}
                  variant="table"
                />
                <div className="text"><Translation id="dashboard.parameters.orderTime.suffix" /></div>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </div>
    
      <div className={classes.separator} />

      <DashboardKpiCards 
        selectedUsecases={selectedUsecases}
        dayBegin={dayBegin}
        dayEnd={dayEnd}
        currency={account.currency}
        forceReload={forceReload}
        orderAttributionTime={orderAttributionTime}
      />

      <DashboardTables 
        selectedUsecases={selectedUsecases}
        dayBegin={dayBegin}
        dayEnd={dayEnd}
        usecasesList={usecasesList}
        forceReload={forceReload}
      />
    

      <ShopifyImportLogoPopup />
      <ShopifySubscriptionPopup />

    </div>    
  );
};