import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../../hooks/useAuth";

export const useNewsletterProducts = (templateId) => {
  const auth = useAuth();
  const [products, setProducts] = useState(null);
  const [productsInTemplate, setProductsInTemplate] = useState([]);
  const [recosInTemplate, setRecosInTemplate] = useState([]);
  
  const addProduct = useCallback((product) => {
    setProducts(prev => [product, ...prev.filter(({ id_product }) => id_product !== product.id_product)]);
  }, []);

  useEffect(() => {
    if (templateId && products) {
      auth.fetch("/api/account_management/saveMjmlTemplate", {
        method: "POST",
        body: JSON.stringify({
          templateId: templateId,
          selectedProductIds: products.map(({ id_product }) => id_product),
        }),
      });
    }
  }, [products, auth, templateId]);

  useEffect(() => {
    if (templateId) {
      auth.fetch(`/data/newsletterProducts?templateId=${templateId}`).then(setProducts);
    }
  }, [templateId, auth]);

  const _setProductsInTemplate = useCallback((productIds) => {
    setProductsInTemplate(productIds.map(id => products.find(({ id_product }) => id === id_product)));
  }, [products]);

  return { 
    products,
    addProduct,
    productsInTemplate,
    setProductsInTemplate: _setProductsInTemplate,
    recosInTemplate,
    setRecosInTemplate
  };
};
