import { palette } from "../../styles/palette";

export const styles = {
  firstSteps: {
    width: "100%",
    borderRadius: 30,
    margin: "21px 0",
    background: palette.primaryWhite,
    marginBottom: 45,
  },

  title: {
    padding: "32px 0 0 47px"
  },

  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    alignContent: "center",
    justifyContent: "space-around",
    gap: 40,
    padding: "31px 70px"
  },

  steps: {
    display: "flex",
    flexDirection: "column",
    rowGap: 13,
    width: "100%",
  },

  stepDescription: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    borderRadius: 20,
    padding: "30px 40px",
    border: "2px solid " + palette.neutralOutlineCards,
    background: palette.neutralCards,
  },

  stepDescriptionButtonAndLink: {
    display: "flex",
    alignItems: "end",
    justifyContent: "space-between",
  },

  stepContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
};
