import { useState, useCallback, useEffect, useContext, useMemo, useRef } from "react";
import { cloneDeep } from "lodash";

import { extractContentFromColumnsComponent } from "./grapesJsKiliba/blocks/columns";
import { TemplateEditorContext } from "./TemplateEditorContext";
import { cloneWordings, getUpdatedWordings, TRANSLATABLE_COMPONENT_TYPES } from "./TranslationManager";

import classes from "./ColumnActions.module.scss";

const RESIZER_WIDTH = 20;

const CURSOR_WIDTH = 13;
const CURSOR_HEIGHT = 20;
const CURSOR_LEFT_RIGHT_PADDING = 6;
const CURSOR_TOP_BOTTOM_PADDING = 12;

const ACTIONS_CONTAINER_TOP_BOTTOM_PADDING = 17;
const ACTIONS_CONTAINER_LEFT_RIGHT_PADDING = 8;
const ACTION_HEIGHT = 17;
const ACTION_GAP = 15;

export const ColumnActions = ({ editor, canvasContainerRef, wordings, language, setIsColumnActionOpened, preheaderHeight, setWordings, isReadOnly }) => {

  const [selectedComponent, setSelectedComponent] = useState(null);
  const [columnsResizers, setColumnResizers] = useState([]);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [resizerNodes, setResizerNodes] = useState({});
  const [cursor, setCursor] = useState(null);
  const [subComponentDragging, setSubComponentDragging] = useState(null);
  const [actions, setActions] = useState();
  const [draggableComponents, setDraggableComponents] = useState([]);
  const timeoutRef = useRef();

  const [resizingCursorIdx, setResizingCursorIdx] = useState(null);
  
  const { refreshBlockActionsPosition, isDragging, variables } = useContext(TemplateEditorContext);
  const SUBJECT_CONTAINER_HEIGHT = useMemo(() => 60 + preheaderHeight, [preheaderHeight]);

  const onComponentSelected = useCallback(model => {
    setSelectedComponent(model);
  }, []);

  const onComponentDeselected = useCallback(component => {
    setSelectedComponent(null);
  }, []);

  useEffect(() => {
    if (editor) {
      editor.on("component:selected", onComponentSelected);
      editor.on("component:deselected", onComponentDeselected);
      return () => {
        editor.off("component:selected", onComponentSelected);
        editor.off("component:deselected", onComponentDeselected);
      };
    }
  }, [editor, onComponentSelected, onComponentDeselected]);

  const addBlankToSelectedComponent = useCallback((component) => {

    const finalComponent = component || selectedComponent;

    Array.from(finalComponent.getEl().parentNode.getElementsByClassName("previewBlank")).forEach(e => e.remove());
    
    const rect = finalComponent.getEl().getBoundingClientRect();

    const div = document.createElement("div");
    div.classList.add("previewBlank");
    div.style.width = `${rect.width}px`;
    div.style.height = `${rect.height}px`;
    finalComponent.getEl().after(div);
  }, [selectedComponent]);

  /* ============== Cursor position calcul ================== */

  const calcCursorPosition = useCallback(() => {
    
    if (!selectedComponent || selectedComponent.attributes.attributes["data-column-component"] !== "true") {
      return ;
    }
    
    const element = selectedComponent.getEl();

    const rect = element.getBoundingClientRect();
    const totalCursorHeight = CURSOR_HEIGHT + CURSOR_TOP_BOTTOM_PADDING * 2;
    const totalCursorWidth = CURSOR_WIDTH + CURSOR_LEFT_RIGHT_PADDING * 2;
    setCursor({
      style: {
        top: rect.top + (rect.height / 2) - (totalCursorHeight / 2) + SUBJECT_CONTAINER_HEIGHT,
        left: rect.left - totalCursorWidth + 1,
        width: CURSOR_WIDTH,
        height: CURSOR_HEIGHT,
        padding: `${CURSOR_TOP_BOTTOM_PADDING}px ${CURSOR_LEFT_RIGHT_PADDING}px`,
      }
    });

  }, [selectedComponent, SUBJECT_CONTAINER_HEIGHT]);

  const onCursorMouseDown = useCallback((event, component) => {
    event.stopPropagation();

    const componentToDrag = component || selectedComponent;

    //close rte before dragging
    editor?.DomComponents.getWrapper().onAll(component => {
      if (TRANSLATABLE_COMPONENT_TYPES.includes(component.attributes.type)) {
        if (component.getView().rteEnabled) {
          component.getView().disableEditing();
        }
      }
    });

    if (componentToDrag.attributes.attributes["data-column-component"] === "true") {
      setWordings(getUpdatedWordings(editor, wordings, language));
      setSubComponentDragging(componentToDrag);

      const element = componentToDrag.getEl();
      const rect = componentToDrag.getEl().getBoundingClientRect();

      element.style.background = componentToDrag.parent().attributes.attributes["background-color"];
      element.style["z-index"] = 10;
      element.style.width = `${rect.width}px`;
      element.style.position = "fixed";

      addBlankToSelectedComponent(componentToDrag);
    }
  }, [selectedComponent, addBlankToSelectedComponent, editor, language, setWordings, wordings]);

  /* ==================== */

  /* ============== Actions position calcul ================== */

  const calcActionsPosition = useCallback(() => {
    if (!selectedComponent || !(selectedComponent.attributes.attributes["data-gjs"] === "kilibaColumn" || selectedComponent.attributes.attributes["data-column-component"] === "true")) {
      return ;
    }

    const element = selectedComponent.getEl();

    let actionsToShow = [];

    if (selectedComponent.attributes.attributes["data-column-component"] === "true") {
      actionsToShow = ["clone", "delete"];
    }

    if (selectedComponent.attributes.attributes["data-gjs"] === "kilibaColumn") {
      const kilibaBlockComponent = selectedComponent.closest("[data-gjs*=\"kilibaBlock\"]");
      const extractedContent = extractContentFromColumnsComponent(kilibaBlockComponent, wordings, language, variables);
      if (extractedContent.columnSizes.length > 2) {
        actionsToShow = ["delete"];
      }
    }

    if (!actionsToShow.length) {
      setActions(null);
      return ;
    }

    const rect = element.getBoundingClientRect();

    const totalActionsHeight = (ACTION_HEIGHT * actionsToShow.length) + (ACTION_GAP * (actionsToShow.length - 1));
    const totalActionsHeightWithPadding = totalActionsHeight + (ACTIONS_CONTAINER_TOP_BOTTOM_PADDING * 2);

    setActions({
      actionsToShow,
      style: {
        top: rect.top + (rect.height / 2) - (totalActionsHeightWithPadding / 2) + SUBJECT_CONTAINER_HEIGHT,
        left: rect.right + 4,
        padding: `${ACTIONS_CONTAINER_TOP_BOTTOM_PADDING}px ${ACTIONS_CONTAINER_LEFT_RIGHT_PADDING}px`,
        gap: ACTION_GAP,
      }
    });

  }, [selectedComponent, SUBJECT_CONTAINER_HEIGHT, language, wordings, variables]);

  /* ==================== */
  
  /* ============== Resizer positions calcul ================== */

  const calcColumnResizers = useCallback(() => {

    if (!editor?.getWrapper()?.getEl()) {
      return ;
    }

    const wrapperRect = editor?.getWrapper().getEl().getBoundingClientRect();

    if (!editor || wrapperRect.width < 480 || !selectedComponent || !selectedComponent.attributes.attributes["data-blocklabel"]?.startsWith("columns") || isDragging) {
      setColumnResizers([]);
      return;
    }

    const element = selectedComponent.getEl();

    if (!element) {
      setColumnResizers([]);
      return;
    }

    const columnElements = element.querySelectorAll("[data-gjs-type^=\"kilibaColumn\"]");
    const newColumnsResizers = [];

    for (let i = 0; i < columnElements.length - 1; i++) {
      const columnRect = element.getBoundingClientRect();
      const rect = columnElements[i].getBoundingClientRect();
      newColumnsResizers.push({
        style: {
          top: columnRect.top + 2 + "px",
          left: rect.right - (RESIZER_WIDTH / 2) + "px",
          height: columnRect.height + "px",
          width: RESIZER_WIDTH + "px",
        }
      });
    }

    setColumnResizers(newColumnsResizers);

  }, [selectedComponent, editor, isDragging]);

  useEffect(() => {
    calcColumnResizers();
  }, [selectedComponent, calcColumnResizers, isDragging]);

  useEffect(() => {
    if (canvasContainerRef.current) {
      const observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          const width = entry.borderBoxSize?.[0].inlineSize;
          if (typeof width === "number" && width !== canvasWidth) {
            setCanvasWidth(width);
            calcColumnResizers();
            calcCursorPosition();
            calcActionsPosition();
          }

          const height = entry.borderBoxSize?.[0].blockSize;
          if (typeof height === "number" && height !== canvasHeight) {
            setCanvasHeight(height);
            calcColumnResizers();
            calcCursorPosition();
            calcActionsPosition();
          }
        }
      });
      observer.observe(canvasContainerRef.current, { box: "border-box" });
      return () => observer.disconnect();
    }
  }, [canvasContainerRef, calcColumnResizers, canvasHeight, canvasWidth, calcCursorPosition, calcActionsPosition]);

  /* ==================== */

  const onStartResize = useCallback((event) => {
    event.stopPropagation();
    const resizerContainerNode = event.target.closest(`.${classes.resizer}`);
    setResizingCursorIdx(parseInt(resizerContainerNode.id.replace("resizer-", "")));
    resizerContainerNode.classList.add(classes.resizing);
  }, []);

  const onMouseMove = useCallback((event) => {
    if (resizingCursorIdx !== null) {
      const x = event.x - (event.target.tagName === "IFRAME" ? 0 : canvasContainerRef.current.getBoundingClientRect().x);
      const element = selectedComponent.getEl();
      const columnElements = element.querySelectorAll("[data-gjs-type^=\"kilibaColumn\"]");

      const leftColumn = columnElements[resizingCursorIdx];
      const rightColumn = columnElements[resizingCursorIdx + 1];
      const parent = leftColumn.parentNode;

      
      const computedStyle = window.getComputedStyle(parent, null);
      const padding = parseInt(computedStyle.getPropertyValue("padding-left")) + parseInt(computedStyle.getPropertyValue("padding-right"));
      
      const right = resizingCursorIdx + 2 < columnElements.length ? columnElements[resizingCursorIdx + 2].getBoundingClientRect().x - parseInt(window.getComputedStyle(rightColumn).getPropertyValue("padding-right")) : parent.getBoundingClientRect().right - padding;
      const leftColumnX = leftColumn.getBoundingClientRect().x - parseInt(window.getComputedStyle(leftColumn).getPropertyValue("padding-left"));
      
      let leftPercent = (x - leftColumnX) * 100 / (parent.getBoundingClientRect().width - padding);
      let rightPercent = (right - x) * 100 / (parent.getBoundingClientRect().width - padding);

      const MIN_PERCENT = 10;

      if (leftPercent < MIN_PERCENT) {
        const gap = MIN_PERCENT - leftPercent;
        leftPercent = MIN_PERCENT;
        rightPercent -= gap;        
      }

      if (rightPercent < MIN_PERCENT) {
        const gap = MIN_PERCENT - rightPercent;
        rightPercent = MIN_PERCENT;
        leftPercent -= gap;  
      }

      leftColumn.style.setProperty("width", `${leftPercent}%`, "important");
      rightColumn.style.setProperty("width", `${rightPercent}%`, "important");
      leftColumn.style.setProperty("max-width", "none");
      rightColumn.style.setProperty("max-width", "none");
      
      calcColumnResizers();
      refreshBlockActionsPosition();
    }

    if (subComponentDragging) {
      const element = subComponentDragging.getEl();

      const rect = element.getBoundingClientRect();
      const y = event.pageY - (event.target.tagName === "IFRAME" ? 0 : canvasContainerRef.current.getBoundingClientRect().y + SUBJECT_CONTAINER_HEIGHT);
      const componentY = rect.y + (rect.height / 2) - parseInt(element.style["margin-top"] || 0);

      const diff = y - componentY;
      element.style["margin-top"] = `${diff}px`;
      
      calcCursorPosition();
      calcActionsPosition();

      const parent = subComponentDragging.parent();
      let idx = 0;
      let child = parent.getChildAt(idx);

      while (child) {
        if (child.getId() !== subComponentDragging.getId() && child.getEl()?.getBoundingClientRect) {
          const childRect = child.getEl().getBoundingClientRect();

          const centerComponentY = rect.y + (rect.height / 2);

          let index = null;

          if (centerComponentY < childRect.top + childRect.height / 2 && centerComponentY > childRect.top) {
            index = child.index();

            if (child.index() > subComponentDragging.index()) {
              index = null;
            }
          }

          if (centerComponentY > childRect.top + childRect.height / 2 && centerComponentY < childRect.bottom) {
            index = child.index() + 1;

            if (child.index() < subComponentDragging.index()) {
              index = null;
            }
          }

          if (index !== null && index !== subComponentDragging.index()) {
            subComponentDragging.move(parent, { at: index });
            subComponentDragging.parent().getView().render();
            const movedElement = subComponentDragging.getEl();
            addBlankToSelectedComponent(subComponentDragging);
            movedElement.style.background = subComponentDragging.parent().attributes.attributes["background-color"];
            movedElement.style["z-index"] = 10;
            movedElement.style.width = `${rect.width}px`;
            movedElement.style.position = "fixed";
            break ;
          }
        }

        idx++;
        child = parent.getChildAt(idx);
      }
    }
  }, [resizingCursorIdx, canvasContainerRef, selectedComponent, calcColumnResizers, refreshBlockActionsPosition, subComponentDragging, SUBJECT_CONTAINER_HEIGHT, calcCursorPosition, addBlankToSelectedComponent, calcActionsPosition]);

  const onMouseUp = useCallback((event) => {
    clearTimeout(timeoutRef.current);
    if (resizingCursorIdx !== null) {
      event.stopPropagation();
      Object.values(resizerNodes).forEach(e => e.classList.remove(classes.resizing));
      const element = selectedComponent.getEl();
      const columnElements = element.querySelectorAll("[data-gjs-type^=\"kilibaColumn\"]");
      const extractedContent = extractContentFromColumnsComponent(selectedComponent, wordings, language, variables);
      
      const columnSizes = Array.from(columnElements).map((elem, idx) => idx === resizingCursorIdx || idx - 1 === resizingCursorIdx ? parseFloat(elem.style.width) : extractedContent.columnSizes[idx]);
      editor.trigger("updateContent", { content: { columnSizes } });
    }

    if (subComponentDragging) {
      subComponentDragging.parent().getView().render();
      calcCursorPosition();
    }

    setSubComponentDragging(null);
    setResizingCursorIdx(null);
  }, [resizingCursorIdx, resizerNodes, selectedComponent, editor, language, wordings, subComponentDragging, calcCursorPosition, variables]);

  useEffect(() => {
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, [onMouseMove, onMouseUp]);

  /* ======== Move resizers to fix z-index issue ======== */

  const onResizerInit = useCallback((node) => {
    if (node && !resizerNodes[node.id]) {
      const cloned = node.cloneNode(true);
      const frame = document.getElementsByClassName("gjs-frames")[0];
      cloned.style.visibility = "visible";
      frame.prepend(cloned);
      setResizerNodes(prev => ({ ...prev, [node.id]: cloned }));
    }
  }, [resizerNodes]);

  useEffect(() => {

    Object.keys(resizerNodes).forEach((key) => {
      const idx = parseInt(key.replace("resizer-", ""));
      if (idx < columnsResizers.length) {
        Object.keys(columnsResizers[idx].style).forEach(style => {
          resizerNodes[key].style[style] = columnsResizers[idx].style[style];
        });
      } else {
        resizerNodes[key].remove();
        setResizerNodes(prev => Object.entries(prev).reduce((acc, [_key, value]) => ({
          ...acc,
          ...(_key !== key ? { [_key]: value } : {}),
        }), {}));
      }
    });

  }, [columnsResizers, resizerNodes]);

  const onResizerClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  useEffect(() => {
    Object.values(resizerNodes).forEach(e => e.addEventListener("mousedown", onStartResize));
    Object.values(resizerNodes).forEach(e => e.addEventListener("click", onResizerClick));
    return () => {
      Object.values(resizerNodes).forEach(e => e.removeEventListener("mousedown", onStartResize));
      Object.values(resizerNodes).forEach(e => e.removeEventListener("click", onResizerClick));
    };
  }, [resizerNodes, onStartResize, onResizerClick]);

  /* ==================== */

  const onRemove = (event) => {
    event.stopPropagation();

    if (selectedComponent.attributes.attributes["data-column-component"] === "true") {
      selectedComponent.remove();
      editor.selectRemove(editor.getSelectedAll());
    }

    if (selectedComponent.attributes.attributes["data-gjs"] === "kilibaColumn") {
      const kilibaBlockComponent = selectedComponent.closest("[data-gjs*=\"kilibaBlock\"]");
      const columnComponents = kilibaBlockComponent.findType("kilibaColumn");
      const extractedContent = extractContentFromColumnsComponent(kilibaBlockComponent, wordings, language);
      const newContent = {};

      const idx = columnComponents.findIndex(component => component.getId() === selectedComponent.getId());

      newContent.columnContents = cloneDeep(extractedContent.columnContents);
      newContent.columnContents.splice(idx, 1);
      newContent.columnSizes = [];

      const block = editor.Blocks.getAll().find(block => block.getLabel() === `columns-${newContent.columnContents.length}`);

      editor.select(kilibaBlockComponent);
      //need timeout for the "updateContent" listener to be updated
      setTimeout(() => {
        editor.trigger("updateContent", { content: newContent, blockId: block.getId() });
      }, 50);
    }
  };

  const onClone = (event) => {
    event.stopPropagation();

    if (selectedComponent.attributes.attributes["data-column-component"] === "true") {
      const actualWordings = getUpdatedWordings(editor, cloneDeep(wordings), language);
      const index = selectedComponent.index();
      const parent = selectedComponent.parent();
      const clonedComponent = selectedComponent.clone();
      parent.append(clonedComponent, { at: index + 1 });

      const newWordings = cloneWordings(clonedComponent, actualWordings, language);

      if (Object.keys(newWordings).length) {
        setWordings(prev => ({ ...prev, ...newWordings }));
      }
    }
  };

  useEffect(() => {
    if (selectedComponent && selectedComponent.attributes.attributes["data-column-component"] === "true") {
      calcCursorPosition();
    } else {
      setCursor(null);
    }
  }, [setIsColumnActionOpened, selectedComponent, calcCursorPosition]);

  useEffect(() => {
    if (selectedComponent && (selectedComponent.attributes.attributes["data-gjs"] === "kilibaColumn" || selectedComponent.attributes.attributes["data-column-component"] === "true")) {
      calcActionsPosition();
      setIsColumnActionOpened(true);
    } else {
      setActions(null);
      setIsColumnActionOpened(false);
    }
  }, [selectedComponent, calcActionsPosition, setIsColumnActionOpened]);

  /* ======= on component start drag ======= */

  const onComponentStartDrag = useCallback(event => {
    if (!subComponentDragging) {
      event.stopPropagation();
      let componentToDrag;
      const element = event.target.closest("[data-column-component=\"true\"]");
      editor?.DomComponents.getWrapper().onAll(component => {
        if (component.getId() === element.getAttribute("id")) {
          componentToDrag = component;
        }
      });
      
      if (!componentToDrag.getView().rteEnabled) {
        timeoutRef.current = setTimeout(() => {
          timeoutRef.current = null;
          editor.select(componentToDrag);
          onCursorMouseDown(event, componentToDrag);
        }, 300);
      }
    }
  }, [subComponentDragging, editor, onCursorMouseDown]);

  useEffect(() => {
    if (isReadOnly) {
      return;
    }

    for (const component of draggableComponents) {
      const element = component.getEl();
      if (element) {
        element.addEventListener("mousedown", onComponentStartDrag, { capture: true });
      }
    }

    return () => {
      for (const component of draggableComponents) {
        const element = component.getEl();
        if (element) {
          element.removeEventListener("mousedown", onComponentStartDrag, { capture: true });
        }
      }
    };
  }, [draggableComponents, onComponentStartDrag, isReadOnly]);

  const onComponentMount = useCallback(component => {
    if (component.attributes.attributes["data-column-component"] === "true") {
      setDraggableComponents(prev => [...prev, component]);
    }
  }, []);

  useEffect(() => {
    if (editor) {
      editor.on("component:mount", onComponentMount);
      return () => editor.off("component:mount", onComponentMount);
    }
  }, [editor, onComponentMount]);

  /* ==================== */

  return (
    <>
      {columnsResizers.map((columnResizer, idx) => (
        <div key={idx} ref={onResizerInit} id={`resizer-${idx}`} className={classes.resizer} style={columnResizer.style}>
          <div className={classes.resizerLine}></div>
          <div className={classes.cursor}>
            <div className={classes.dotsLine}>
              <div className={classes.dot} />
              <div className={classes.dot} />
            </div>
            <div className={classes.dotsLine}>
              <div className={classes.dot} />
              <div className={classes.dot} />
            </div>
            <div className={classes.dotsLine}>
              <div className={classes.dot} />
              <div className={classes.dot} />
            </div>
          </div>
        </div>
      ))}
      {cursor && 
        <div className={`${classes.dragCursor} ${subComponentDragging ? classes.dragging : ""}`} onMouseDown={onCursorMouseDown} onClick={e => e.stopPropagation()} style={cursor.style}>
          <div className={classes.dotsLine}>
            <div className={classes.dot} />
            <div className={classes.dot} />
          </div>
          <div className={classes.dotsLine}>
            <div className={classes.dot} />
            <div className={classes.dot} />
          </div>
          <div className={classes.dotsLine}>
            <div className={classes.dot} />
            <div className={classes.dot} />
          </div>
        </div>
      }
      {actions &&
        <div className={classes.actionsContainer} style={actions.style}>
          {actions.actionsToShow.includes("clone") && <div onClick={onClone} className={classes.action}><i className="fa-solid fa-clone" /></div>}
          {actions.actionsToShow.includes("delete") && <div onClick={onRemove} className={classes.action}><i className="fa-solid fa-trash-can" /></div>}
        </div>
      }
    </>
  );
};