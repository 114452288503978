import React, { useContext, useEffect, useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import { AccountContext } from "../contexts/context";

import "@fortawesome/fontawesome-pro/css/all.min.css";

// Main Components
import { LeftMenu } from "./webmenu/LeftMenu.js";
import { TopBar } from "./webmenu/TopBar.js";

// Submenus Components
// import { Subscription } from "./payment/Subscription.js";
import { Subscription } from "./payment/Subscription/Subscription.js";
import { Integration } from "./settings/Integration/Integration.jsx";

import { SubscriptionInfo as SubscriptionInfoV2 } from "./payment/SubscriptionInfo/SubscriptionInfoV2.js";
import { SubscriptionError } from "./payment/SubscriptionError.js";
import { SubscriptionSuccess } from "./payment/SubscriptionSuccess.js";
import { UsecaseList } from "./usecase/UsecaseList.js";
import { Usecase } from "./usecase/Usecase/Usecase.js";
import { EditProfile } from "./settings/EditProfile.jsx";
import { Blacklist } from "./settings/Blacklist.js";
import { WhiteList } from "./settings/Whitelist.jsx";
import { EmailLang } from "./settings/EmailLang.js";
import { Customers } from "./Customers/Customers.js";
import { TemplateCustomization } from "./TemplateCustomization/TemplateCustomization.jsx";
import { Products } from "./products/Products.js";
import { TemplateEditor } from "./TemplateEditor/TemplateEditor";
import { Newsletter } from "./Newsletter/Newsletter";
import { Dashboard } from "./Dashboard/Dashboard.jsx";

// Utilisation de l'eventSystem
import { EventSystem } from "../eventsystem/EventSystem.js";

import { palette } from "../styles/palette";
import { DisconnectedModulePopup } from "./DisconnectedModulePopup.jsx";
import { useAuth } from "../hooks/useAuth";

const PAGES_WITHOUT_TOP_BAR = ["/editor"];

export function LoggedInApp() {
  const { account } = useContext(AccountContext);
  const auth = useAuth();

  // Check if user is logged and account is valid
  if(!auth.loggedIn() || !account) {
    auth.logout();
    return;
  }

  // Detect impersonation request
  const search = window.location.search;
  const params = new URLSearchParams(search);
  if (params.get("impersonate")) {
    auth.impersonate(params.get("impersonate"));
    window.location.href = "/"; // Can't window.location.reload() because of infinite loop with impersonate param (need to remove param with href = "/")
    return;
  }
  
  return <App />;
}

export function App() {
  const { account } = useContext(AccountContext);
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();

  const [menuIsExpanded, setMenuIsExpanded] = useState(true);

  useEffect(() => {
    datadogRum.setUser({
      id: account._id,
      name: account.company_name,
      plan: account.owner,
      cms: account.CMS
    });
    return () => datadogRum.clearUser();
  }, [account]);

  // Ping backend to register user activity
  useEffect(() => {
    if(!auth.isImpersonating() && window.kiliba_hello_id !== auth.getAccountId()) {
      window.kiliba_hello_id = auth.getAccountId();
      auth.fetch("/api/account_management/hello", {
        method: "POST"
      });
    }
  }, [auth]);
  
  // Account linked actions
  useEffect(() => {   
    if (account.step_register < 2) {
      history.push("/on_boarding");
      return;
    }
  }, [account, auth, history]);

  // Handle EventSystem (legacy, please remove this 💩)
  useEffect(() => {
    EventSystem.subscribe("url_switch", url => history.push(url));
    return () => EventSystem.unsubscribe("url_switch", null);
  }, [history]);

  return (
    <div className="App" style={{ backgroundColor: palette.neutralBCGGrey }}>
      <DisconnectedModulePopup />
      
      <Grid container item direction="column" xs={12} sm={12} spacing={0}>
        <Grid item xs={12}>
          <LeftMenu onExpansion={(expanded) => setMenuIsExpanded(expanded)} />
        </Grid>

        <Grid container item xs={12} spacing={0}>
          {!PAGES_WITHOUT_TOP_BAR.find(elem => location.pathname.startsWith(elem)) && <TopBar />}

          <div
            className="Main-App"
            style={{
              left: menuIsExpanded ? 212 : 105,
              width: `calc(100% - ${menuIsExpanded ? 212 : 105}px)`,
              background:palette.neutralBCGGrey,
            }}
          >
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/usecase" component={UsecaseList} />
              <Route exact path="/customers" component={Customers} />
              <Route exact path="/blacklist" component={Blacklist} />
              <Route exact path="/whitelist" component={WhiteList} />
              <Route exact path="/email_lang_settings" component={EmailLang} />
              <Route exact path="/subscription" component={Subscription} />
              <Route exact path="/subscription_info" component={SubscriptionInfoV2} />
              <Route exact path="/integration" component={Integration} />
              <Route exact path="/payment_success" component={SubscriptionSuccess} />
              <Route exact path="/payment_failed" component={SubscriptionError} />
              <Route exact path="/myprofile" component={EditProfile} />
              <Route exact path="/usecase_details" component={Usecase} />
              <Route exact path="/template_customization" component={TemplateCustomization} />
              <Route exact path="/products" component={Products} />
              {<Route exact path="/editor/:templateId" render={props => <TemplateEditor props={props} menuIsExpanded={menuIsExpanded} />} />}
              {<Route exact path="/newsletter" component={Newsletter} />}
              <Redirect to="/" />
            </Switch>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}