import { formatHtmlIntoShortText } from "../../../../../services/text.service";
import { getPriceFromProduct, getTitleTranslation, getDescriptionTranslation } from ".";

const LABEL = "multiProducts2";
const CATEGORY = "product:multiProducts";

export const multiProducts2 = (variables, nbProducts = 2) => ({
  label: `${LABEL}-${nbProducts}`,
  category: CATEGORY,
  block: (overrides) => {

    const {
      products,
      fontFamily, 
      buttonTextColor,
      buttonsColor,
      textColor,
      backgroundColor,
      imagesUrl,
      imagesHref,
      imagesAlt,
      buttonsHref,
      buttonsText,
      productsName,
      buttonRadius,
      pricesText,
      productsDescription,
      currency,
      pricesIsVisible,
      currencyBefore,
      recoTypes,
    } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    if (!products || products.length !== nbProducts) {
      return {
        id: `${LABEL}-${nbProducts}`,
        label: `${LABEL}-${nbProducts}`,
        category: CATEGORY,
        activate: false,
        content: "",
      };
    }

    const mjml = `
      <mj-wrapper data-gjs="kilibaBlock" background-color="${backgroundColor}" padding-top="0" padding-bottom="0" full-width="full-width">
        <mj-section>
          ${products.map((product, idx) => `
            <mj-column data-id-product="${product.id_product}" css-class="multiProducts2-info ${recoTypes?.length ? "productRecoContainer" : ""}" width="${Math.round(100 / nbProducts)}%" vertical-align="top" ${recoTypes?.length ? `data-reco-type="${recoTypes[idx]}"` : ""}>
              <mj-image css-class="multiProducts2-image" href="{{imageHref${idx}}}" alt="{{imageAlt${idx}}}" align="center" src="{{imageUrl${idx}}}"></mj-image>
              <mj-text align="center" font-family="${fontFamily}" padding-top="0px" padding-bottom="7px" line-height="14px" color="${textColor}" padding-bottom="10px" font-size="14px" font-weight="700" data-text-id="productName${idx}">{{productName${idx}}}</mj-text>
              <mj-text font-family="${fontFamily}" padding-right="10px" padding-top="0px" align="center" line-height="14px" color="${textColor}" padding-bottom="10px" font-size="12px" data-text-id="productDescription${idx}">{{description${idx}}}</mj-text>
              <mj-text ${!pricesIsVisible[idx] ? "css-class=\"priceHidden\"" : "" } data-product-id="${product.id_product}" data-readonly="true" align="center" font-family="${fontFamily}" padding-top="0px" font-weight="700" line-height="16px" color="${textColor}" padding-bottom="12px" font-size="14px" data-text-id="priceText${idx}">
                {{price${idx}}}
              </mj-text>

              <mj-button border-radius="${buttonRadius}" href="{{buttonHref${idx}}}" css-class="multiProducts2-button" font-family="${fontFamily}" line-height="16px" color="${buttonTextColor}" font-size="14px" padding-top="0px" padding-bottom="10px" inner-padding="8px 8px" background-color="${buttonsColor[idx]}" align="center">
                {{button${idx}}}
              </mj-button>

            </mj-column>
          `).join("")}        
        </mj-section> 
      </mj-wrapper>
    `;

    const wordings = {
      ...products.reduce((acc, product, idx) => ({
        ...acc,
        [`button${idx}`]: (buttonsText && buttonsText[idx]) || {
          fr: "Acheter",
          en: "Buy",
          es: "¡Comprar!",
          de: "Kaufen",
          nl: "Kopen",
          it: "Acquista",
          pt: "Comprar",
        },
        [`productName${idx}`]: (productsName && productsName[idx]) || {
          fr: getTitleTranslation("fr", product.title),
          en: getTitleTranslation("en", product.title),
          es: getTitleTranslation("es", product.title),
          de: getTitleTranslation("de", product.title),
          nl: getTitleTranslation("nl", product.title),
          it: getTitleTranslation("it", product.title),
          pt: getTitleTranslation("pt", product.title),
        },
        [`price${idx}`]: (pricesText && pricesText[idx]) || {
          fr: getPriceFromProduct(product, currency, currencyBefore),
          en: getPriceFromProduct(product, currency, currencyBefore),
          es: getPriceFromProduct(product, currency, currencyBefore),
          de: getPriceFromProduct(product, currency, currencyBefore),
          nl: getPriceFromProduct(product, currency, currencyBefore),
          it: getPriceFromProduct(product, currency, currencyBefore),
          pt: getPriceFromProduct(product, currency, currencyBefore),
        },
        [`description${idx}`]: (productsDescription && productsDescription[idx]) || {
          fr: formatHtmlIntoShortText(getDescriptionTranslation("fr", product.description)),
          en: formatHtmlIntoShortText(getDescriptionTranslation("en", product.description)),
          es: formatHtmlIntoShortText(getDescriptionTranslation("es", product.description)),
          de: formatHtmlIntoShortText(getDescriptionTranslation("de", product.description)),
          nl: formatHtmlIntoShortText(getDescriptionTranslation("nl", product.description)),
          it: formatHtmlIntoShortText(getDescriptionTranslation("it", product.description)),
          pt: formatHtmlIntoShortText(getDescriptionTranslation("pt", product.description)),
        },
      }), {}),
    };

    const _variables = {
      ...products.reduce((acc, product, idx) => ({
        ...acc,
        [`imageUrl${idx}`]: imagesUrl[idx],
        [`imageHref${idx}`]: imagesHref[idx],
        [`imageAlt${idx}`]: imagesAlt[idx],
        [`buttonHref${idx}`]: buttonsHref[idx],
      }), {}),
    };

    return {
      id: `${LABEL}-${nbProducts}`,
      label: `${LABEL}-${nbProducts}`,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        variables: _variables,
        wordings,
        editableProperties: [
          { key: "products", type: "products", label: "", group: "newsletter.rightMenu.propertyGroup.products", properties: [
            { key: "imagesUrl", type: "image", label: "newsletter.rightMenu.property.selectedImage", group: "newsletter.rightMenu.propertyGroup.image" },
            { key: "imagesHref", type: "link", label: "newsletter.rightMenu.property.imageLink", group: "newsletter.rightMenu.propertyGroup.image" },
            { key: "imagesAlt", type: "string", label: "newsletter.rightMenu.property.alt", group: "newsletter.rightMenu.propertyGroup.image" },
            { key: "buttonsHref", type: "link", label: "newsletter.rightMenu.property.buttonLink", group: "newsletter.rightMenu.propertyGroup.button" },
            { key: "buttonsColor", type: "color", label: "newsletter.rightMenu.property.buttonColor", group: "newsletter.rightMenu.propertyGroup.button" },
            { key: "pricesIsVisible", type: "price", label: "", group: "newsletter.rightMenu.property.priceIsVisible" },
          ], keys: ["products", "imagesHref", "imagesAlt", "imagesUrl", "buttonsHref", "buttonsColor", "buttonsText", "productsName", "pricesText", "productsDescription", "pricesIsVisible"]},
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ],
      }
    };
  },
  style: `
    .multiProducts2-image img {
      max-height: 150px !important;
      max-width: 150px !important;
      width: auto !important;
      height: auto;
      margin: 0 auto;
    }

    .multiProducts2-image table {
      height: 100%;
    }

    .priceHidden {
      display: none !important;
      visibility:hidden;
      mso-hide:all;
      font-size:1px;
      line-height:1px;
      max-height:0px;
      max-width:0px;
      opacity:0;
      overflow:hidden;
    }
  `,
  mediaQueryStyle: `
    @media only screen and (min-width:480px) {
      .multiProducts2-info td div {
        text-align: left !important;
      }

      .multiProducts2-info table {
        max-width: 250px;
      }

      .multiProducts2-button {
        float: left;
      }

      .multiProducts2-image {
        float: left;
        height: 200px;
      }

      .multiProducts2-image img {
        margin: 0;
      }      
    }
  `,
});