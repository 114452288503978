import { useEffect, useCallback, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
import { TemplateEditorContext } from "./TemplateEditorContext";

export const LANGUAGES = ["fr", "en", "es", "de", "nl", "it", "pt"];
export const TRANSLATABLE_COMPONENT_TYPES = ["mj-text", "mj-button", "readonly-mj-text", "column-component-mj-text", "column-component-mj-button"];
export const COMPONENT_TYPES_WITH_TRANSLATABLE_ATTRIBUTES = ["mj-image", "kilibaBlock-mj-hero", "preview-mj-hero", "mj-button", "column-component-mj-image", "column-component-mj-button", "mj-image-reco-product"];

export const getContentElementFromComponent = (type, element) => {
  if (type === "mj-text" || type === "readonly-mj-text" || type === "column-component-mj-text") {
    return element.children[0].children[0];
  }

  if (type === "mj-button" || type === "column-component-mj-button") {
    return element.querySelector("a") || element.querySelector("p");
  }

  return "";
};

export const getUpdatedWordings = (editor, wordings, language) => {
  editor.getComponents().at(0).onAll(component => {
    if (TRANSLATABLE_COMPONENT_TYPES.includes(component.attributes.type)) {
      const props = component.get("script-props");
      const wordingId = props[0].wordingId;
      if (wordings[wordingId]) {
        wordings[wordingId][language] = component.getInnerHTML();
      }
    }
  });

  return wordings;
};

export const shouldTranslate = (wordings, availableLanguages) => {
  for (const wording of Object.values(wordings)) {
    for (const lang of availableLanguages) {
      if (!wording[lang]) {
        return true;
      }
    }
  }

  return false;
};

const setAttribute = (component, key, value) => {
  if (["src"].includes(key)) {
    component.set(key, value);
  } else {
    component.addAttributes({ [key]: value });
    const attrs = component.getAttributes();
    component.setAttributes({ ...attrs, [key]: value });
  }
};

export const onLanguageChange = (editor, language, wordings, variables) => {

  //Update wordings in editor
  editor.getComponents().at(0).onAll(component => {
    if (TRANSLATABLE_COMPONENT_TYPES.includes(component.attributes.type)) {
      const props = component.get("script-props");
      const wordingId = props[0].wordingId;
      component.components(wordings[wordingId][language]);
    }
  });

  //Update variable in editor
  editor.getComponents().at(0).onAll(component => {
    if (COMPONENT_TYPES_WITH_TRANSLATABLE_ATTRIBUTES.includes(component.attributes.type)) {
      const props = component.get("script-props");
      const translatableAttributes = props?.length ? props[0].translatableAttributes : [];
      for (const translatableAttribute of translatableAttributes || []) {
        setAttribute(component, translatableAttribute.key, variables[translatableAttribute.attributeId][language]);
      }
    }
  });
};

export const removeUselessWordings = (template, wordings) => {
  const finalWordings = wordings;
  
  Object.keys(finalWordings).forEach(key => {
    if (!template.includes(`{{${key}}}`) && key !== "subject") {
      delete finalWordings[key];
    }
  });

  return finalWordings;
};

//clone component and wordings
export const cloneWordings = (component, wordings, language) => {
  const newWordings = {};
  component.onAll(_component => {
    if (TRANSLATABLE_COMPONENT_TYPES.includes(_component.attributes.type)) {
      const props = _component.get("script-props");
      const wordingId = props[0].wordingId;
      const newWordingId = `wording-${uuidv4()}`;
      newWordings[newWordingId] = cloneDeep(wordings[wordingId]);
      _component.set("script-props", [{ wordingId: newWordingId }]);
      _component.components(newWordings[newWordingId][language]);
    }
  });

  return newWordings;
};

export const TranslationManager = ({ editor, blocks, wordings, setWordings, setPrevWordings }) => {
  
  const { language, setVariables, variables } = useContext(TemplateEditorContext);
  
  const getBlockFromComponent = useCallback(component =>  {
      
    let blockLabel = null;
    let parent = component;
    while (!blockLabel && parent) {
      const props = parent.get("script-props");
      if (props.length && props[0].fromBlock) {
        blockLabel = props[0].fromBlock;
      }
      parent = parent.parent();
    }

    if (!blockLabel) {
      return null;
    }

    return blocks.find(block => block.getLabel() === blockLabel);
  }, [blocks]);

  const onComponentMount = useCallback(component => {
    const block = getBlockFromComponent(component);
    
    //wording translation management
    if (TRANSLATABLE_COMPONENT_TYPES.includes(component.attributes.type)) {
      const props = component.get("script-props");

      if (!block || (props.length && props[0].wordingId)) {
        //If block null component from template (use saved wordings)
        
        const elem = getContentElementFromComponent(component.attributes.type, component.getEl());

        let wordingId;

        if (props.length && props[0].wordingId) {
          wordingId = props[0].wordingId;
        } else {
          wordingId = elem.innerHTML.replace(/{|}/g, "");
          component.set("script-props", [{ wordingId, wordingValue: wordings[wordingId] }]);
        }
        
        if (wordings[wordingId]) {
          component.components(wordings[wordingId][language]);
        }

      } else {

        //Initialise component from block
        const blockWordings = block.attributes.attributes.wordings;

        const wordingId = `wording-${uuidv4()}`;
        const wording = Object.values(LANGUAGES).reduce((acc, lang) => ({ ...acc, [lang]: component.getInnerHTML() }), {});

        const missingLangs = [];

        //Match all text variables in html
        [...component.toHTML().matchAll(/{{[^ {}]+}}/g)].forEach(([textVariable]) => {
          const value = textVariable.replace(/{|}/g, "");

          if (!blockWordings[value]) {
            return ;
          }

          Object.keys(wording).forEach(lang => {
            if (!blockWordings[value][lang]) {
              missingLangs.push(lang);
            }
            wording[lang] = wording[lang].replaceAll(textVariable, blockWordings[value][lang]);
          });
        });

        const wordingValue = Object.entries(wording).reduce((acc, [lang, value]) => !missingLangs.includes(lang) ? { ...acc, [lang]: value } : acc, {});

        setWordings(prev => ({
          ...prev,
          [wordingId]: cloneDeep(wordingValue),
        }));
        setPrevWordings(prev => ({
          ...prev,
          [wordingId]: cloneDeep(wordingValue),
        }));

        component.set("script-props", [{ wordingId, wordingValue: wording }]);
        component.components(wording[language]);
      }
    }

    //variables management
    if (COMPONENT_TYPES_WITH_TRANSLATABLE_ATTRIBUTES.includes(component.attributes.type)) {

      const _variables = block ? block.attributes.attributes.variables : variables;

      const translatableAttributes = [];
      for (const attributeKey of Object.keys(component.attributes.attributes)) {
        const attribute = component.attributes.attributes[attributeKey];                
        if (attribute && attribute.match(/\{\{[a-zA-Z0-9-]+\}\}/)) {
          const attributeId = block ? `${attributeKey}-${uuidv4()}` : attribute.replace(/{|}/g, "");
          const key = attribute.replace(/{|}/g, "");
          translatableAttributes.push({
            key: attributeKey,
            attributeId,
            value: _variables[key],
          });
          setAttribute(component, attributeKey, _variables[key][language]);
        }
      }
      
      const newVariables = translatableAttributes.reduce((acc, attribute) => ({ ...acc, [attribute.attributeId]: attribute.value }), {});

      setVariables(prev => ({
        ...prev,
        ...newVariables,
      }));

      const _props = component.get("script-props");
      component.set("script-props", [{ ...( _props.length ? _props[0] : {}), translatableAttributes }]);
    }
  }, [getBlockFromComponent, language, setWordings, wordings, setVariables, variables, setPrevWordings]);


  useEffect(() => {
    if (editor) {
      editor.on("component:mount", onComponentMount);
      return () => {
        editor.off("component:mount", onComponentMount);
      };
    }
  }, [editor, onComponentMount]);

  return null;
};