import { makeTranslatable } from "../utils";

import { product6 } from "../products/product6";
import { product1 } from "../products/product1";
import { product2 } from "../products/product2";
import { product3 } from "../products/product3";
import { product4 } from "../products/product4";
import { product7 } from "../products/product7";
import { newProduct1 } from "../products/newProduct1";

import { multiProducts2 } from "../products/multiProducts2";
import { multiProducts3 } from "../products/multiProducts3";
import { multiProducts4 } from "../products/multiProducts4";
import { extractContentFromProductComponent } from "../products";

export const previewRecoProduct = {
  absolute_url: "",
  price: "XX,XX€",
  image_url: "https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/productImagePreview.png",
  title: {
    fr: "Nom de ton produit",
    en: "Name of your product",
    es: "Nombre de su producto",
    de: "Name deines Produkts",
    nl: "Naam van uw product",
    it: "Nome del prodotto",
    pt: "Nome do seu produto",
  },
  description: {
    fr: "",
    en: "",
    es: "",
    de: "",
    nl: "",
    it: "",
    pt: "",
  }
};

const transformProductToRecoBlock = (block) => {

  return {
    ...block,
    label: `reco${block.label}`,
    category: `reco${block.category}`,
    block: (overrides) => {
      const result = block.block(overrides);

      let editableProperties = [];

      if (result.attributes?.editableProperties?.find(property => property.key === "products")) {
        editableProperties = [
          { key: "products", type: "products", label: "", group: "newsletter.rightMenu.propertyGroup.products", properties: [
            { key: "buttonsColor", type: "color", label: "newsletter.rightMenu.property.buttonColor", group: "newsletter.rightMenu.propertyGroup.button" },
            { key: "pricesIsVisible", type: "price", label: "", group: "newsletter.rightMenu.property.priceIsVisible" },
          ], keys: ["products", "imagesHref", "imagesAlt", "imagesUrl", "buttonsHref", "buttonsColor", "buttonsText", "productsName", "pricesText", "productsDescription", "pricesIsVisible", "recoTypes"]},
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ];
      } else if (result.attributes?.editableProperties?.length) {
        editableProperties = [
          { key: "product", type: "product", label: "", group: "newsletter.rightMenu.propertyGroup.product", properties: [
            { key: "buttonColor", type: "color", label: "newsletter.rightMenu.property.buttonColor", group: "newsletter.rightMenu.propertyGroup.button" },
            { key: "priceIsVisible", type: "price", label: "", group: "newsletter.rightMenu.property.priceIsVisible" },
          ], keys: ["product", "imageHref", "imageAlt", "imageUrl", "buttonHref", "buttonColor", "buttonText", "productName", "priceText", "productDescription", "priceIsVisible", "recoType"]},
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ];
      }

      return {
        ...result,
        id: `reco${result.id}`,
        label: `reco${result.label}`,
        category: `reco${result.category}`,
        content: result.content
          .replace(/(data-text-id="productName[0-9]*")/g, "$1 data-readonly=\"true\"")
          .replace(/(data-text-id="productDescription[0-9]*")/g, "$1 data-readonly=\"true\"")
          .replace(/data-id-product="[^"]+"/g, ""),
        attributes: {
          ...result.attributes,
          editableProperties,
        }
      };
    },
    previewStyle: `
      .productRecoContainer img {
        background: #F9F9F9;
      }
    `
  };
};

export const getRecoProductBlocks = ({ fontFamily, buttonTextColor, accentColor, textColor, dominantColor, templateInfo, buttonRadius, currency, secondaryColor, priceIsVisible, currencyBefore }) => {

  const product = previewRecoProduct;
  const productHref = makeTranslatable("");
  const imageAlt = makeTranslatable("");
  const imageUrl = makeTranslatable(product.image_url);

  return [
    transformProductToRecoBlock(product6({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl, imageHref: productHref, imageAlt, buttonHref: productHref, buttonRadius, currency, priceIsVisible, currencyBefore })),
    transformProductToRecoBlock(product1({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl, imageHref: productHref, imageAlt, buttonHref: productHref, buttonRadius, currency, priceIsVisible, currencyBefore })),
    transformProductToRecoBlock(product2({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl, imageHref: productHref, imageAlt, buttonHref: productHref, buttonRadius, currency, priceIsVisible, currencyBefore })),
    transformProductToRecoBlock(product3({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl, imageHref: productHref, imageAlt, buttonHref: productHref, buttonRadius, currency, priceIsVisible, currencyBefore })),
    transformProductToRecoBlock(product4({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl, imageHref: productHref, imageAlt, buttonHref: productHref, buttonRadius, currency, priceIsVisible, currencyBefore })),
    transformProductToRecoBlock(product7({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl, imageHref: productHref, imageAlt, buttonHref: productHref, buttonRadius, currency, priceIsVisible, currencyBefore })),

    transformProductToRecoBlock(newProduct1({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl, imageHref: productHref, imageAlt, buttonHref: productHref, buttonRadius, currency, secondaryColor, priceIsVisible, currencyBefore })),

    transformProductToRecoBlock(multiProducts2({ products: [product], fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl: [imageUrl, imageUrl], imagesHref: [productHref, productHref], imagesAlt: [imageAlt, imageAlt], buttonsHref: [productHref, productHref], buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore })),
    transformProductToRecoBlock(multiProducts3({ products: [product], fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl: [imageUrl, imageUrl], imagesHref: [productHref, productHref], imagesAlt: [imageAlt, imageAlt], buttonsHref: [productHref, productHref], buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore })),
    transformProductToRecoBlock(multiProducts4({ products: [product], fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl: [imageUrl, imageUrl], imagesHref: [productHref, productHref], imagesAlt: [imageAlt, imageAlt], buttonsHref: [productHref, productHref], buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore })),

    transformProductToRecoBlock(multiProducts2({ products: [product], fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl: [imageUrl, imageUrl, imageUrl], imagesHref: [productHref, productHref, productHref], imagesAlt: [imageAlt, imageAlt, imageAlt], buttonsHref: [productHref, productHref, productHref], buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore }, 3)),
    transformProductToRecoBlock(multiProducts3({ products: [product], fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl: [imageUrl, imageUrl, imageUrl], imagesHref: [productHref, productHref, productHref], imagesAlt: [imageAlt, imageAlt, imageAlt], buttonsHref: [productHref, productHref, productHref], buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore }, 3)),
    transformProductToRecoBlock(multiProducts4({ products: [product], fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl: [imageUrl, imageUrl, imageUrl], imagesHref: [productHref, productHref, productHref], imagesAlt: [imageAlt, imageAlt, imageAlt], buttonsHref: [productHref, productHref, productHref], buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore }, 3)),
  ];
};

export const extractContentFromRecoProductComponent = (component, wordings, language, variables) => {

  const content = extractContentFromProductComponent(component, wordings, language, variables);

  content.recoType = component.attributes.attributes["data-reco-type"];
  content.recoTypes = [];

  component.onAll(innerComponent => {
    if (innerComponent.attributes.attributes["data-reco-type"]) {
      content.recoTypes.push(innerComponent.attributes.attributes["data-reco-type"]);
    } 
  });

  if (content.productsName.length === 1) {
    content.products = null;
    content.product = { ...previewRecoProduct, id_product: content.productId };
  } else {
    content.product = null;
    content.products = content.recoTypes.map((id) => ({ ...previewRecoProduct, id_product: id }));
  }

  return content;
};