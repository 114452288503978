import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const CustomTooltipStyle = withStyles({
  tooltip: {
    color: props => props.color || "white",
    fontSize: "12px",
    fontFamily: "Coolvetica Book",
    backgroundColor: props => props.toolTipBackgroundColor || "#0E0E0F",
    zIndex: "1000000",
    borderRadius: props => props.borderRadius || "unset",
    boxShadow: props => props.boxShadow || "unset",
    maxWidth: props => props.width || "unset",
    display: "flex"
  },
  arrow: {
    color: props => props.backgroundColor || "#0E0E0F",
  },
})(Tooltip);

class CustomTooltip extends Component {
  render() {
    const { placement, title, children, color, arrow, toolTipBackgroundColor, borderRadius, boxShadow, width } = this.props;
    return (
      <CustomTooltipStyle
        title={title}
        placement={placement ? placement : undefined}
        color={color}
        arrow={arrow}
        toolTipBackgroundColor={toolTipBackgroundColor}
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        width={width}
      >
        {children}
      </CustomTooltipStyle>
    );
  }
}

export { CustomTooltip as Tooltip };
