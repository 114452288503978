import { formatHtmlIntoShortText } from "../../../../../services/text.service";
import { getTitleTranslation, getDescriptionTranslation } from ".";

const LABEL = "product4";
const CATEGORY = "product:card";

export const product4 = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {

    const { product, fontFamily, buttonTextColor, buttonColor, textColor, backgroundColor, imageUrl, imageHref, imageAlt = "", buttonHref, buttonText, productName, productDescription, buttonRadius, recoType } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    if (!product) {
      return {
        id: LABEL,
        label: LABEL,
        category: CATEGORY,
        activate: false,
        content: "",
      };
    }

    const mjml = `
      <mj-wrapper data-id-product="${product.id_product}" data-gjs="kilibaBlock" background-color="${backgroundColor}" padding-top="0" padding-bottom="0" full-width="full-width" ${recoType ? "css-class=\"productRecoContainer\"" : ""} ${recoType ? `data-reco-type="${recoType}"` : ""}>
        <mj-section>
          <mj-column width="10%"></mj-column>
          <mj-column vertical-align="middle" width="40%">
            <mj-image href="{{imageHref}}" alt="{{imageAlt}}" padding-left="10px" padding-right="10px" css-class="product4-image" src="{{imageUrl}}"></mj-image>
          </mj-column>
          <mj-column width="40%" css-class="product4-info" vertical-align="middle">
            <mj-text font-family="${fontFamily}" padding-left="10px" padding-right="10px" padding-top="0px" padding-bottom="10px" font-weight="700" align="center" line-height="16px" color="${textColor}" padding-bottom="10px" font-size="14px" data-text-id="productName">{{productName}}</mj-text>

            <mj-text font-family="${fontFamily}" padding-left="10px" padding-right="10px" padding-top="0px" align="center" line-height="14px" color="${textColor}" padding-bottom="20px" font-size="12px" data-text-id="productDescription">{{description}}</mj-text>

            <mj-button border-radius="${buttonRadius}" href="{{buttonHref}}" font-family="${fontFamily}" padding-left="10px" padding-right="10px" line-height="16px" color="${buttonTextColor}" font-size="14px" padding-top="0px" padding-bottom="0px" inner-padding="8px 15px" background-color="${buttonColor}">
              {{button}}
            </mj-button>
          </mj-column>
          <mj-column width="10%"></mj-column>
        </mj-section> 
      </mj-wrapper>
    `;

    const wordings = {
      button: buttonText || {
        fr: "Découvrir",
        en: "Discover",
        es: "Descubra",
        de: "Entdecken Sie",
        nl: "Ontdek",
        it: "Scoprire",
        pt: "Descobrir",
      },
      description: productDescription || {
        fr: formatHtmlIntoShortText(getDescriptionTranslation("fr", product.description)),
        en: formatHtmlIntoShortText(getDescriptionTranslation("en", product.description)),
        es: formatHtmlIntoShortText(getDescriptionTranslation("es", product.description)),
        de: formatHtmlIntoShortText(getDescriptionTranslation("de", product.description)),
        nl: formatHtmlIntoShortText(getDescriptionTranslation("nl", product.description)),
        it: formatHtmlIntoShortText(getDescriptionTranslation("it", product.description)),
        pt: formatHtmlIntoShortText(getDescriptionTranslation("pt", product.description)),
      },
      productName: productName || {
        fr: getTitleTranslation("fr", product.title),
        en: getTitleTranslation("en", product.title),
        es: getTitleTranslation("es", product.title),
        de: getTitleTranslation("de", product.title),
        nl: getTitleTranslation("nl", product.title),
        it: getTitleTranslation("it", product.title),
        pt: getTitleTranslation("pt", product.title),
      }
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        variables: {
          imageUrl,
          imageHref,
          imageAlt,
          buttonHref,
        },
        wordings,
        editableProperties: [
          { key: "product", type: "product", label: "", group: "newsletter.rightMenu.propertyGroup.product", properties: [
            { key: "imageUrl", type: "image", label: "newsletter.rightMenu.property.selectedImage", group: "newsletter.rightMenu.propertyGroup.image" },
            { key: "imageHref", type: "link", label: "newsletter.rightMenu.property.imageLink", group: "newsletter.rightMenu.propertyGroup.image" },
            { key: "imageAlt", type: "string", label: "newsletter.rightMenu.property.alt", group: "newsletter.rightMenu.propertyGroup.image" },
            { key: "buttonHref", type: "link", label: "newsletter.rightMenu.property.buttonLink", group: "newsletter.rightMenu.propertyGroup.button" },
            { key: "buttonColor", type: "color", label: "newsletter.rightMenu.property.buttonColor", group: "newsletter.rightMenu.propertyGroup.button" },
          ], keys: ["product", "imageHref", "imageAlt", "imageUrl", "buttonHref", "buttonColor", "buttonText", "productName", "priceText", "productDescription", "priceIsVisible"] },
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ]
      }
    };
  },
  mediaQueryStyle: `
    @media only screen and (min-width:480px) {
      .product4-info td div {
        text-align: left !important;
      }

      .product4-info td {
        float: left;
      }
    }
  `,
  style: `
    .product4-image td {
      width: inital !important;
    }
    
    .product4-image img {
      max-width: 200px;
      max-height: 200px;
      margin: 0 auto;
    }
  `,
});