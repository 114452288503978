import { useState, useContext, useCallback, useEffect } from "react";
import mjml2html from "mjml-browser";
import { cloneDeep } from "lodash";
import { Dialog } from "../common/Dialog";
import { Translation } from "../common/Translation";
import { AccountContext } from "../../contexts/context";
import { useAuth } from "../../hooks/useAuth";
import { extractContentFromPreheaderComponent } from "./grapesJsKiliba/blocks/preheader";
import { cleanString } from "./rightMenu/GeneratedTextMenu.jsx";

import { getUpdatedWordings } from "./TranslationManager";

import classes from "./TemplateEditorPreviewPopup.module.scss";
import { TemplateEditorContext } from "./TemplateEditorContext";

export const TemplateEditorPreviewPopup = ({ isOpen, close, menuIsExpanded, editor, wordings, selectedDevice }) => {

  const [isDesktop, setIsDesktop] = useState(true);
  const [user, setUser] = useState(null);
  const auth = useAuth();
  const [html, setHtml] = useState("");
  const { account } = useContext(AccountContext);
  const { language, variables } = useContext(TemplateEditorContext);

  const [preheader, setPreheader] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setIsDesktop(selectedDevice === "desktop");
    }
  }, [isOpen, selectedDevice]);

  useEffect(() => {
    if (!user) {
      auth.getUser().then(setUser);
    }
  }, [user, auth]);

  const replaceVariable = useCallback(string => {
    const capitalizeFirstLetter = (string) => {
      return string && string.length ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    };

    if (!user || !account) {
      return string;
    }

    return string
      .replace(/#FIRSTNAME#|#PRENOM#|#NOMBRE#/g, capitalizeFirstLetter(user.attributes.given_name))
      .replace(/#LASTNAME#|#NOM#|#APELLIDO#/g, capitalizeFirstLetter(user.attributes.family_name))
      .replace(/#SHOP_NAME#|#NOM_SITE#|#NOMBRE_SITIO#/g, capitalizeFirstLetter(account.company_name) || "")
      .replace(/#YEAR#|#ANNEE#|#AÑO#/g, String(new Date().getFullYear()));

  }, [user, account]);

  const loadPreview = useCallback(async () => {
    const mjml = editor.getHtml();

    if (!mjml) {
      return ;
    }

    const result = await auth.fetch("/api/account_management/fillMjmlWithFakeProducts", {
      method: "POST",
      body: JSON.stringify({
        mjml,
        language
      })
    });

    let html = mjml2html(result.mjml, { validationLevel: "skip" }).html;

    const updatedWordings = getUpdatedWordings(editor, cloneDeep(wordings), language); 

    Object.entries(updatedWordings).forEach(([wordingId, wordings]) => {
      if (wordings) {
        html = html.replaceAll(`{{${wordingId}}}`, wordings[language]);
      }
    });

    html = replaceVariable(html);

    Object.entries(variables).forEach(([key, variable]) => {
      if (wordings) {
        html = html.replaceAll(`{{${key}}}`, variable[language]);
      }
    });

    setHtml(html);

    let preheaderComponent = null;

    editor.getComponents().at(0).onAll(component => {
      if (component.attributes.attributes["data-blocklabel"] === "preheader") {
        preheaderComponent = component;
      }
    });
    
    let preheaderValue = null;

    if (preheaderComponent) {
      const content = extractContentFromPreheaderComponent(preheaderComponent, wordings, language);
      if (!content.isVisible) {
        preheaderValue = cleanString(content.title[language]);
      }
    }

    setPreheader(replaceVariable(preheaderValue || ""));

  }, [editor, wordings, language, replaceVariable, variables, auth]);

  useEffect(() => {
    if (isOpen) {
      loadPreview();
    }
  }, [isOpen, loadPreview]);


  return (
    <Dialog
      isOpen={isOpen}
      close={close}
      closeStyle="string"
      PaperProps={{
        style: {
          maxWidth: "90%",
          display: "block",
          overflow: "overlay",
          margin: `0 0 0 ${menuIsExpanded ? "212px" : "105px"}`,
          borderRadius: 40,
        },
      }}
    >
      <div className={classes.container}>
        
        <div className={classes.devices}>
          <div 
            className={`${classes.device} ${isDesktop ? classes.deviceSelected : ""}`}
            onClick={() => setIsDesktop(true)}
          >
            <i className={`fa-solid fa-laptop ${classes.iconDesktop}`} />
            <span className="text"><Translation id="templateEditorPreviewPopup.desktop" /></span>
          </div>

          <div
            className={`${classes.device} ${!isDesktop ? classes.deviceSelected : ""}`}
            onClick={() => setIsDesktop(false)}
          >
            <i className={`fa-regular fa-mobile-notch ${classes.iconMobile}`} />
            <span className="text"><Translation id="templateEditorPreviewPopup.mobile" /></span>
          </div>
        </div>

        <div className={classes.content}>
          <div className={classes.emailInfo}>
            <div className="textMedium"><Translation id="email.from" /> <span className="text">{account.replyToEmail ? account.replyToEmail : <Translation id="templateEditorPreviewPopup.email.placeholder" />}</span></div>
            <div className="textMedium"><Translation id="email.subject" /> <span className="text">{wordings?.subject ? replaceVariable(wordings?.subject[language] || "") : ""}</span></div>
            {preheader && <div className="textMedium"><Translation id="tempalteEditor.preheader" /> : <span className="text">{preheader}</span></div>}
          </div>

          <div className={classes.previewContainer}>
            <div className={classes.preview} style={{ width: isDesktop ? "100%" : "320px"}}>
              {isDesktop && <div className={classes.previewDesktopHeader}>
                <div className={classes.desktopCircle} />
                <div className={classes.desktopCircle} />
                <div className={classes.desktopCircle} />
              </div>}

              <iframe
                className={classes.previewIframe}
                style={{ borderRadius: !isDesktop ? "16px" : undefined, height: isDesktop ? "80vh" : "620px" }}
                onLoad={event => event.target.contentDocument.body.style["overscroll-behavior"] = "contain"}
                srcDoc={html}
                title="previewTemplateEmail"
              ></iframe>
            </div>
          </div>
        </div>

      </div>
    </Dialog>
  );
};