
import classes from "./KiCheckbox.module.scss";

export const KiCheckbox = ({ checked, label, onChange, backgroundColor, iconColor, value, uncheckedBorderColor, labelClass }) => {

  return (
    <label
      className={classes.container}
      style={{
        "--kicheckbox-background-color": backgroundColor || "black",
        "--kicheckbox-icon-color": iconColor || "white",
        "--kicheckbox-unchecked-border-color": uncheckedBorderColor || backgroundColor || "black",
      }}  
    >
      <input type="checkbox" checked={checked} onChange={onChange} value={value} />
      <i className={`${classes.icon} fa-solid fa-check`} style={{ display: checked ? "block" : "none" }} />
      {label && <span class={labelClass || "info"}>{label}</span>}
    </label>
  );
};