import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { Dialog } from "../common/Dialog";
import { TextField } from "../common/Input/TextField";
import { Translation } from "../common/Translation";
import { CustomButton } from "../common/Button/CustomButton";
import { useAuth } from "../../hooks/useAuth";
import { getUpdatedWordings } from "./TranslationManager";
import { EventSystem } from "../../eventsystem/EventSystem";

import classes from "./TemplateEditorSendMailPopup.module.scss";
import { HTTPResponseStatusError } from "../AuthService";

export const TemplateEditorSendMailPopup = ({ editor, wordings, isOpen, close, template, language, variables }) => {

  const intl = useIntl();
  const auth = useAuth();
  const [email, setEmail] = useState("");
  const [emailSendLoading, setEmailSendLoading] = useState(false);

  useEffect(() => {
    auth.getUser().then(user => setEmail(user.attributes.email));
  }, [auth]);

  async function sendNewsletterPreview() {
    const mjmlPayload = !template ? editor.getHtml() : template;
    const wordingsPayload = editor ? getUpdatedWordings(editor, wordings, language) : wordings;

    setEmailSendLoading(true);
    try {
      await auth.fetch("/account_management/sendNewsletterPreview", {
        method: "POST",
        body: JSON.stringify({
          email: email,
          mjmlTemplate: mjmlPayload,
          wordings: wordingsPayload,
          language: language,
          variables,
        })
      });
      
      EventSystem.newNotification(
        "notification.action",
        intl.messages["templateEditorSendMailPopup.send.success"]
      );
      close();
    } catch(error) {
      let errorMessage = intl.messages["templateEditorSendMailPopup.send.error"];
      if(error instanceof HTTPResponseStatusError) {
        try {
          const { code } = await error.response.json();
          if(code === "ERR_INVALID_EMAIL") {
            errorMessage = intl.messages["templateEditorSendMailPopup.notAllowed.error"];
          }
        } catch {}
      }
      EventSystem.newNotification("notification.error", errorMessage);
    }
    setEmailSendLoading(false);
  }
  
  return (
    <Dialog 
      isOpen={isOpen}
      close={close}
      style={{
        padding: "80px 120px",
      }}
    >
      <div className={classes.container}>
        <div className={`display4 ${classes.title}`}><Translation id="templateEditorSendMailPopup.title" /></div>
        <div className={classNames("text", classes.desc)}>
          <Translation id="templateEditorSendMailPopup.desc1" />
        </div>

        <div className={classes.inputContainer}>
          <TextField value={email} onChange={event => setEmail(event.target.value)} />
        </div>

        <CustomButton 
          type={"primary"}
          size="lg"
          loading={emailSendLoading}
          onClick={() => sendNewsletterPreview()}
        >
          <Translation id="button.sendMail" />
        </CustomButton>
      </div>
    </Dialog>
  );
};