import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import Tooltip from "@material-ui/core/Tooltip";

import { Translation } from "../common/Translation";
import { useAuth } from "../../hooks/useAuth";

import { getFormattedNumber } from "../../tools/getFormattedNumber";

import classes from "./DashboardKpiCards.module.scss";

const numberWithSpaces = (x) => {
  // add space in numbers
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const KpiCard = ({ value, text, icon, unit, tooltip }) => {
  return (
    <div className={classes.kpiCard}>
      <h1>{value} {unit && <span className={classes.percent}>{unit}</span>}</h1>
      <div className={`${classes.kpiCardText} textMedium`}>
        <Translation id={text} />&nbsp;
        {tooltip && 
          <Tooltip title={tooltip} arrow classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}>
            <i className="fa-solid fa-circle-info" />
          </Tooltip>
        }
      </div>
      <div className={classes.iconContainer}>
        <i className={icon} />
      </div>
    </div>
  );
};

export const DashboardKpiCards = ({ selectedUsecases, dayBegin, dayEnd, currency, forceReload, orderAttributionTime }) => {

  const auth = useAuth();
  const intl = useIntl();

  const [page, setPage] = useState(0);
  const [generalStatistics, setGeneralStatistics] = useState();
  const [orderGeneralStatistics, setOrderGeneralStatistics] = useState();

  const usecases = useMemo(() => {
    const usecases = [...selectedUsecases];
    if (selectedUsecases.includes("winterSales")) {
      selectedUsecases.push("solde");
    }
    return usecases;
  }, [selectedUsecases]);

  useEffect(() => {
    if (!dayBegin || !dayEnd || !usecases) {
      return;
    }

    auth.fetch(`/api/data/getGeneralStatistics?usecases=${usecases}&startDate=${dayBegin}&endDate=${dayEnd}`).then(setGeneralStatistics);
  }, [auth, dayEnd, dayBegin, usecases]);

  useEffect(() => {

    if (!dayBegin || !dayEnd || !usecases) {
      return;
    }

    auth.fetch(`/api/data/getOrderGeneralStatistics?usecases=${usecases}&startDate=${dayBegin}&endDate=${dayEnd}`).then(setOrderGeneralStatistics);
  }, [auth, dayEnd, dayBegin, usecases, forceReload]);

  const caTooltip = useMemo(() => {
    return (
      <span className="info">
        <Translation id="dashboard.kpi.ca.tooltip1" />
        <Translation id={`dashboard.kpi.tooltip.${orderAttributionTime}`} /><br/>
        <Translation id="dashboard.kpi.ca.tooltip2" />
      </span>
    );

  }, [orderAttributionTime]);

  const ordersTooltip = useMemo(() => {

    return (
      <div className="info">
        <Translation id="dashboard.kpi.orders.tooltip1" />
        <Translation id={`dashboard.kpi.tooltip.${orderAttributionTime}`} />
        <Translation id="dashboard.kpi.orders.tooltip2" />
      </div>
    );

  }, [orderAttributionTime]);

  return (
    <div className={classes.container}>
      <div className={classes.kpiCardsContainer}>
        {page === 0 ?
          <>
            <KpiCard
              value={generalStatistics ? numberWithSpaces(getFormattedNumber(generalStatistics?.send, "", intl.locale)) : "-"}
              text="card.title.emails_sent"
              icon="fa-solid fa-envelope-circle-check"
            />
            <KpiCard
              value={generalStatistics ? numberWithSpaces(getFormattedNumber(generalStatistics?.open, "", intl.locale)) : "-"}
              text="card.title.open_rate"
              icon="fa-solid fa-envelope-open"
              unit="%"
            />
            <KpiCard
              value={generalStatistics ? numberWithSpaces(getFormattedNumber(generalStatistics?.click, "", intl.locale)) : "-"}
              text="card.title.clic_rate"
              icon="fa-solid fa-computer-mouse"
              unit="%"
            />
            <KpiCard
              value={orderGeneralStatistics ? getFormattedNumber(orderGeneralStatistics?.turnover, "", intl.locale) : "-"}
              text="card.title.ca"
              icon="fa-solid fa-money-check-dollar"
              unit={currency}
              tooltip={caTooltip}
            />
          </> :
          <>
            <KpiCard
              value={orderGeneralStatistics ? getFormattedNumber(orderGeneralStatistics?.nbOrder, "", intl.locale) : "-"}
              text="card.title.nb_order"
              icon="fa-solid fa-box-open-full"
              tooltip={ordersTooltip}
            />
            <KpiCard
              value={orderGeneralStatistics ? numberWithSpaces(getFormattedNumber(orderGeneralStatistics?.avgOrder, "", intl.locale)) : "-"}
              text="card.title.avg_order"
              icon="fa-solid fa-basket-shopping"
              unit={currency}
            />
            <KpiCard
              value={generalStatistics ? getFormattedNumber(generalStatistics?.hardbounce, "", intl.locale) : "-"}
              text="card.title.hard_bounce"
              icon="fa-solid fa-arrow-right-arrow-left"
              unit="%"
            />
            <KpiCard
              value={generalStatistics ? getFormattedNumber(generalStatistics?.unsubscribe, "", intl.locale) : "-"}
              text="card.title.unsubscribe_rate"
              icon="fa-solid fa-trash-xmark"
              unit="%"
            />
          </>
        }
      </div>

      <div className={classes.kpiButton} onClick={() => setPage(page === 0 ? 1 : 0)}>
        {page === 0 ?
          <i className="fa-solid fa-angle-right" /> :
          <i className="fa-solid fa-angle-left" />
        }
      </div>
    </div>
  );
};