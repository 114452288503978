import { useMemo, useState, useContext, useEffect } from "react";
import DraggableList from "react-draggable-list";
import { useIntl } from "react-intl";

import { Translation } from "../../common/Translation";
import { makeTranslatable } from "../grapesJsKiliba/blocks/utils";
import { TemplateEditorContext } from "../TemplateEditorContext";

import { previewRecoProduct } from "../grapesJsKiliba/blocks/recoProducts";

import classes from "./RecoProductMenu.module.scss";

export const RECOS = [
  {
    imagePreview: "https://production-kiliba-assets.s3.eu-central-1.amazonaws.com/kiliba/productImages/recoBestsellerPreview.png",
    image: "https://production-kiliba-assets.s3.eu-central-1.amazonaws.com/kiliba/productImages/recoBestseller.png",
    name: "bestseller",
  },
  {
    imagePreview: "https://production-kiliba-assets.s3.eu-central-1.amazonaws.com/kiliba/productImages/recoBestVisitPreview.png",
    image: "https://production-kiliba-assets.s3.eu-central-1.amazonaws.com/kiliba/productImages/recoBestVisit.png",
    name: "mostVisited"
  },
  {
    imagePreview: "https://production-kiliba-assets.s3.eu-central-1.amazonaws.com/kiliba/productImages/recoAlsPreview.png",
    image: "https://production-kiliba-assets.s3.eu-central-1.amazonaws.com/kiliba/productImages/recoAls.png",
    name: "customerRecommendation"
  },
  
];

export const RecoProductList = ({ onClick }) => {

  return (
    <div className={classes.recosContainer}>
      {RECOS.map(reco => (
        <div key={reco.name} className={classes.reco} onClick={() => onClick(reco)}>
          <img 
            alt=""
            src={reco.image}
            height={60}
          />
          <div className={`info ${classes.recoText}`}>
            <Translation id={`recoProductMenu.reco.${reco.name}`} />
          </div>
        </div>
      ))}
    </div>
  );
};

const AddRecoCard = ({ item, dragHandleProps, onDeleteReco, itemSelected }) => {

  const onTrashMouseDown = (event) => {
    event.stopPropagation();
    onDeleteReco(item.idx);
  };

  return (
    <div {...dragHandleProps} className={`${classes.addedReco}`}>
      <div className={`${classes.addedRecoRightPart} ${itemSelected !== 0 ? classes.addedRecoDraggged : ""}`}>
        <img 
          alt=""
          src={item.image}
          height={60}
        />
        <div className="info"><Translation id={`recoProductMenu.addedReco.${item.name}`} /></div>
      </div>
      <div>
        <i className={`fa-solid fa-trash-can ${classes.recoTrash}`} onMouseDown={onTrashMouseDown} />
      </div>
    </div>
  );
};

export const RecoProductMenu = ({ onChange, content }) => {

  const intl = useIntl();

  const { recosInTemplate } = useContext(TemplateEditorContext);
  const [addedRecos, setAddedRecos] = useState(null);
  const [disabledPopover, setDisabledPopover] = useState(null);

  const _setAddedRecos = (newValue) => {
    if (!newValue.every(elem => !!elem.id)) {
      setAddedRecos(newValue.map((elem, idx) => ({
        ...elem,
        id: idx + 1,
      })));
    } else {
      setAddedRecos(newValue);
    }
  };

  useEffect(() => {
    if (addedRecos === null) {
      if (content.product && !content.products) {
        _setAddedRecos([RECOS.find(reco => reco.name === content.recoType)]);
      } else if (content.products) {
        _setAddedRecos(content.recoTypes.map(type => RECOS.find(reco => reco.name === type)));
      } else {
        _setAddedRecos([]);
      }
    }
  }, [addedRecos, content]);

  const MAX_RECOS = useMemo(() => {
    return Math.min(20 - recosInTemplate.length, 3);
  }, [recosInTemplate]);

  const updateRecos = (newAddedRecos) => {

    if (newAddedRecos) {
      _setAddedRecos(newAddedRecos);
    } else {
      newAddedRecos = addedRecos;
    }

    if (!newAddedRecos.length) {
      onChange({
        product: undefined,
        products: undefined,
        imageHref: undefined,
        imageUrl: undefined,
        buttonHref: undefined,
        imagesHref: undefined,
        imagesUrl: undefined,
        buttonsHref: undefined,
        recoType: undefined,
        recoTypes: undefined,
      });
    } else if (newAddedRecos.length === 1) {
      const reco = newAddedRecos[0];
      onChange({ 
        imageUrl: makeTranslatable(reco.imagePreview),
        recoType: reco.name,
        product: { ...previewRecoProduct, title: makeTranslatable(intl.messages[`recoProductMenu.reco.${reco.name}.productName`]) },
        products: null,
      });
    } else {
      onChange({
        product: null,
        products: newAddedRecos.map(reco => ({ ...previewRecoProduct, title: makeTranslatable(intl.messages[`recoProductMenu.reco.${reco.name}.productName`]) })),
        recoTypes: newAddedRecos.map(reco => reco.name),
        imagesUrl: newAddedRecos.map(reco => makeTranslatable(reco.imagePreview)),
      });
    }
  };

  const onAddReco = (reco) => {

    if (addedRecos.length >= MAX_RECOS) {
      const container = document.getElementsByClassName(classes.container)[0];
      const element = document.getElementsByClassName(classes.recosContainer)[0];

      setDisabledPopover({
        top: element?.getBoundingClientRect().bottom - container?.getBoundingClientRect().top + 15,
        wording: addedRecos.length === 3 ? "rightMenuEditContent.addRecoInput.error" : "rightMenu.disabledPopover.recoproduct",
      });
    } else {
      const newAddedRecos = [...addedRecos, reco];
      updateRecos(newAddedRecos);
    }
  };
  
  const onDeleteReco = (idx) => {
    const newAddedRecos = [...addedRecos];
    newAddedRecos.splice(idx, 1);
    updateRecos(newAddedRecos);
  };

  const onMoveEnd = (newList, movedItem, oldIndex, newIndex) => {
    const moveItemInArray = (arr, oldIndex, newIndex) => {
      const element = arr[oldIndex];
      arr.splice(oldIndex, 1);
      arr.splice(newIndex, 0, element);
    };

    const newAddedRecos = [...addedRecos];
    moveItemInArray(newAddedRecos, oldIndex, newIndex);
    updateRecos(newAddedRecos);
  };

  useEffect(() => {
    if (disabledPopover) {
      const timeout = setTimeout(() => setDisabledPopover(null), 4000);
      return () => clearTimeout(timeout);
    }
  }, [disabledPopover]);


  return (
    <div className={classes.container}>
      <h3 className={classes.title}>
        <Translation id="recoProductMenu.title" />
      </h3>
      <div className={`text ${classes.subtitle}`}>
        <Translation id="recoProductMenu.subtitle" />
      </div>

      <RecoProductList onClick={onAddReco} />

      {!!addedRecos && !!addedRecos.length && 
        <div>
          <div className={classes.separator} />
          {addedRecos.length > 1 && <div className={`text ${classes.dragHelp}`}><Translation id="templateEditor.rightMenu.recosInput.tip" /></div>}
          <div className={classes.addedRecosContainer}>
            <DraggableList
              itemKey="id"
              list={addedRecos.map((reco, idx) => ({ ...reco, idx }))}
              template={(props) => <AddRecoCard {...props} onDeleteReco={onDeleteReco} />}
              onMoveEnd={onMoveEnd}
            />
          </div>
        </div>
      }
      {disabledPopover && 
        <div className={`text ${classes.disabledPopover}`} style={{ top: disabledPopover.top }}>
          <Translation id={disabledPopover.wording} />
          <i className="fa-solid fa-times" onClick={() => setDisabledPopover(null)}/>
        </div>
      }
    </div>
  );
};
