import { getPriceFromProduct, getTitleTranslation } from ".";

const LABEL = "newProduct1";
const CATEGORY = "product:new";

export const newProduct1 = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {

    const { product, fontFamily, buttonTextColor, buttonColor, textColor, backgroundColor, imageUrl, imageHref, imageAlt = "", buttonHref, buttonText, productName, tagText, priceText, buttonRadius, currency, secondaryColor, priceIsVisible, currencyBefore, recoType } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    if (!product) {
      return {
        id: LABEL,
        label: LABEL,
        category: CATEGORY,
        activate: false,
        content: "",
      };
    }

    const mjml = `
      <mj-wrapper data-id-product="${product.id_product}" data-gjs="kilibaBlock" background-color="${backgroundColor}" padding-top="0" padding-bottom="0" full-width="full-width" ${recoType ? "css-class=\"productRecoContainer\"" : ""} ${recoType ? `data-reco-type="${recoType}"` : ""}>
        <mj-section>
          <mj-column width="10%"></mj-column>
          <mj-column vertical-align="middle" width="40%">
            <mj-image href="{{imageHref}}" alt="{{imageAlt}}" padding-left="10px" padding-right="10px" css-class="newproduct1-image" src="{{imageUrl}}"></mj-image>
          </mj-column>
          <mj-column width="40%" css-class="newproduct1-info" vertical-align="middle">
            <mj-button css-class="newproduct1-tag" color="#000000" border-radius="15px" padding-left="10px" padding-right="10px" padding-top="0px" padding-bottom="10px" line-height="14px" font-size="12px" background-color="${secondaryColor}" data-text-id="tagText">{{tag}}</mj-button>

            <mj-text padding-left="10px" padding-right="10px" font-family="${fontFamily}" padding-top="0px" padding-bottom="10px" align="center" line-height="14px" color="${textColor}" padding-bottom="10px" font-size="12px" data-text-id="productName">{{productName}}</mj-text>

            <mj-text ${!priceIsVisible ? "css-class=\"priceHidden\"" : "" } data-readonly="true" data-product-id="${product.id_product}" padding-left="10px" padding-right="10px" font-family="${fontFamily}" padding-top="0px" align="center" font-weight="700" line-height="16px" color="${textColor}" padding-bottom="20px" font-size="14px" data-text-id="priceText">
              {{price}}
            </mj-text>

            <mj-button border-radius="${buttonRadius}" href="{{buttonHref}}" padding-left="10px" padding-right="10px" font-family="${fontFamily}" line-height="16px" color="${buttonTextColor}" font-size="14px" padding-top="0px" padding-bottom="0px" inner-padding="8px 15px" background-color="${buttonColor}">
              {{button}}
            </mj-button>
          </mj-column>
          <mj-column width="10%"></mj-column>
        </mj-section> 
      </mj-wrapper>
    `;

    const wordings = {
      button: buttonText || {
        fr: "Acheter",
        en: "Buy",
        es: "¡Comprar!",
        de: "Kaufen",
        nl: "Kopen",
        it: "Acquista",
        pt: "Comprar",
      },
      productName: productName || {
        fr: getTitleTranslation("fr", product.title),
        en: getTitleTranslation("en", product.title),
        es: getTitleTranslation("es", product.title),
        de: getTitleTranslation("de", product.title),
        nl: getTitleTranslation("nl", product.title),
        it: getTitleTranslation("it", product.title),
        pt: getTitleTranslation("pt", product.title),
      },
      tag: tagText || {
        fr: "Nouveauté",
        en: "New",
        es: "Noticia",
        de: "Neuheiten",
        nl: "Nieuw",
        it: "Notizie",
        pt: "Notícia",
      },
      price: priceText || {
        fr: getPriceFromProduct(product, currency, currencyBefore),
        en: getPriceFromProduct(product, currency, currencyBefore),
        es: getPriceFromProduct(product, currency, currencyBefore),
        de: getPriceFromProduct(product, currency, currencyBefore),
        nl: getPriceFromProduct(product, currency, currencyBefore),
        it: getPriceFromProduct(product, currency, currencyBefore),
        pt: getPriceFromProduct(product, currency, currencyBefore),
      }
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        variables: {
          imageUrl,
          imageHref,
          imageAlt,
          buttonHref,
        },
        wordings,
        editableProperties: [
          { key: "product", type: "product", label: "", group: "newsletter.rightMenu.propertyGroup.product", properties: [
            { key: "imageUrl", type: "image", label: "newsletter.rightMenu.property.selectedImage", group: "newsletter.rightMenu.propertyGroup.image" },
            { key: "imageHref", type: "link", label: "newsletter.rightMenu.property.imageLink", group: "newsletter.rightMenu.propertyGroup.image" },
            { key: "imageAlt", type: "string", label: "newsletter.rightMenu.property.alt", group: "newsletter.rightMenu.propertyGroup.image" },
            { key: "buttonHref", type: "link", label: "newsletter.rightMenu.property.buttonLink", group: "newsletter.rightMenu.propertyGroup.button" },
            { key: "buttonColor", type: "color", label: "newsletter.rightMenu.property.buttonColor", group: "newsletter.rightMenu.propertyGroup.button" },
            { key: "priceIsVisible", type: "price", label: "", group: "newsletter.rightMenu.property.priceIsVisible" },
          ], keys: ["product", "imageHref", "imageAlt", "imageUrl", "buttonHref", "buttonColor", "buttonText", "productName", "priceText", "productDescription", "priceIsVisible"] },
          { key: "secondaryColor", type: "color", label: "newsletter.rightMenu.property.badgeColor", group: "newsletter.rightMenu.propertyGroup.badge" },
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ]
      }
    };
  },
  mediaQueryStyle: `
    @media only screen and (min-width:480px) {
      .newproduct1-info td div {
        text-align: left !important;
      }

      .newproduct1-info td {
        float: left;
      }
    }

    
  `,
  style: `
    .newproduct1-tag p {
      padding: 0px !important;
      margin: 5px 10px !important;
    }

    .newproduct1-image td {
      width: inital !important;
    }
    
    .newproduct1-image img {
      max-width: 200px;
      max-height: 200px;
      margin: 0 auto;
    }

    .priceHidden {
      display: none !important;
      visibility:hidden;
      mso-hide:all;
      font-size:1px;
      line-height:1px;
      max-height:0px;
      max-width:0px;
      opacity:0;
      overflow:hidden;
    }
  `,
});
