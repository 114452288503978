import { useCallback ,useContext, useEffect, useMemo, useState } from "react";

import { RightMenuForm } from "./RightMenuForm";
import { Translation } from "../../common/Translation";
import { CustomButton } from "../../common/Button/CustomButton";
import { ProductMenu } from "./ProductMenu";
import { RecoProductMenu } from "./RecoProductMenu";
import { GeneratedTextProductMenu } from "./GeneratedTextProductMenu";
import { GeneratedTextMenu } from "./GeneratedTextMenu";
import { checkContent } from "./RightMenu";
import { PromoCodeOptionalMenu } from "./../grapesJsKiliba/optionalMenu/promoCodeMenu";
import { TemplateEditorContext } from "../TemplateEditorContext";
import { makeTranslatable } from "../grapesJsKiliba/blocks/utils";
import { ImageGaleryMenu, imagePreview } from "./ImageGaleryMenu/ImageGaleryMenu";

import classes from "./RightMenuAddBlock.module.scss";
import { defaultWordings } from "../grapesJsKiliba/blocks/texts";

export const RightMenuAddBlock = ({
  category,
  blocks,
  updateForm,
  formBlockId,
  defaultBlockId, 
  scrollableContainerClass, 
  onCancel,
  setNavigationItemSelected,
  updateContent,
  content,
  templateId,
  navigationItemSelected,
  editor,
  language,
  onAddBlock,
  canvasContainerRef,
  addBlockCurrentStep,
  setAddBlockCurrentStep,
}) => {
  const [stepIdx, setStepIdx] = useState(0);
  const [previousNavigationItemSelected, setPreviousNavigationItemSelected] = useState(navigationItemSelected);
  const [formStepViewed, setFormStepViewed] = useState(false);
  const [forceCancelText, setForceCancelText] = useState(false);
  const { setIsFirstLoading } = useContext(TemplateEditorContext);  
  const [previewComponent, setPreviewComponent] = useState();


  const steps = useMemo(() => {

    switch (category) {
      case "text":
        return [{ type: "content", name: "textProduct", neededKeys: ["productIds"] }, { type: "form", name: "form" }, ...(!content?.disableGPT ? [{ type: "content", name: "text" }] : [])];
      case "product":
        return [{ type: "content", name: "product" }, { type: "form", name: "form" }];
      case "image": 
        return [{ type: "content", name: "image", customFooter: true }, { type: "form", name: "form" }];
      case "promoCode":
        return [{ type: "content", name: "promoCode" }, { type: "form", name: "form" }];
      case "preheader":
        return [{ type: "content", name: "text" }];
      case "recoproduct":
        return [{ type: "content", name: "recoproduct" }, { type: "form", name: "form" }];
      default:
        return [{ type: "form", name: "form" }];
    }

  }, [category, content]);

  useEffect(() => {
    setNavigationItemSelected(steps[stepIdx].type);
  }, [stepIdx, steps, setNavigationItemSelected]);

  useEffect(() => {
    if (previousNavigationItemSelected !== navigationItemSelected && navigationItemSelected !== steps[stepIdx].type) {
      const newIdx = steps.findIndex(step => step.type === navigationItemSelected);
      if (newIdx >= 0) {
        setStepIdx(newIdx);
      }
    }
    setPreviousNavigationItemSelected(navigationItemSelected);

    if (steps[stepIdx].type === "form") {
      setFormStepViewed(true);
    }

  }, [navigationItemSelected, steps, stepIdx, previousNavigationItemSelected]);

  const isContentOk = useMemo(() => {
    return !steps.find(step => step.type === "content") || checkContent(category, content);
  }, [steps, category, content]);

  const isFormOk = useMemo(() => {
    return formBlockId || (steps.length === 1 && steps[0].type === "form") || !steps.find(step => step.type === "form");
  }, [formBlockId, steps]);  

  const checkStep = useCallback((idx) => {
    const step = steps[idx];
    if (step.type === "form") {
      return true;
    }

    if (step.neededKeys?.length) {
      return step.neededKeys.every(key => content && content[key]);
    }

    return checkContent(category, content);
  }, [steps, category, content]);

  const onNext = () => {
    if (isContentOk && isFormOk) {
      onCancel();
    } else {
      let nextIdx = null;
      for (let idx = 0; idx < steps.length; idx++) {
        if (!checkStep(idx) || (steps[idx].type === "form" && idx > stepIdx)) {
          nextIdx = idx;
          break ;
        }
      }

      if (nextIdx) {
        setStepIdx(nextIdx);
      } else if (stepIdx < steps.length - 2) {
        setStepIdx(prev => prev + 1);
      } else {
        setNavigationItemSelected(prev => prev === "form" ? "content" : "form");
      }
    }
  };

  const onFinish = () => {
    setIsFirstLoading(true);
    onCancel();
  };

  /* ======== TEXT PREVIEW CLICK MANAGMENT ======== */

  const onPreviewTextClick = useCallback(() => {
    if (category === "text") {
      if (!isContentOk) {
        updateContent({
          disableGPT: true,
          title: defaultWordings.title,
          subtitle: defaultWordings.subtitle,
          text: defaultWordings.text,
        });
      }
      setTimeout(() => {
        setForceCancelText(true);
      }, 50);
    }
  }, [category, isContentOk, updateContent]);

  useEffect(() => {
    editor.on("textPreviewClick", onPreviewTextClick);
    return () => editor.off("textPreviewClick", onPreviewTextClick);
  }, [editor, onPreviewTextClick]);

  useEffect(() => {
    if (forceCancelText) {
      setForceCancelText(false);
      onCancel(false, { preventReload: true });
      setNavigationItemSelected("form");
    }
  }, [content, onCancel, forceCancelText, setNavigationItemSelected]);

  /* ==================================== */

  /* ============= get / update preview component ================ */

  useEffect(() => {
    editor.getComponents().at(0)?.onAll(component => {
      if (component.attributes.type.startsWith("preview-")) {
        setPreviewComponent(component);
      }
    });
  }, [editor]);

  const onComponentMount = useCallback((component) => {
    if (component.attributes.type.startsWith("preview-")) {
      setPreviewComponent(component);
    }
  }, []);

  useEffect(() => {
    editor.on("component:mount", onComponentMount);
    return () => editor.off("component:mount", onComponentMount);
  }, [editor, onComponentMount]);

  /* ==================================== */

  const isStepOk = useMemo(() => checkStep(stepIdx), [checkStep, stepIdx]);


  useEffect(() => {
    setAddBlockCurrentStep(steps[stepIdx]);
  }, [steps, stepIdx, setAddBlockCurrentStep]);

  if (!addBlockCurrentStep) {
    return ;
  }

  return (
    <div className={classes.container}>
      {addBlockCurrentStep.name === "form" &&
        <RightMenuForm
          blocks={blocks}
          selectedCategory={category}
          allowCrop={false}
          onChange={updateForm}
          selectedBlockId={formBlockId || defaultBlockId}
          isEdit={false}
          scrollableContainerClass={scrollableContainerClass}
          allowScroll={false}
          editor={editor}
          templateId={templateId}
          updateContent={updateContent}
          content={content}
          language={language}
        />
      }
      {addBlockCurrentStep.name === "image" &&
        <>
          <ImageGaleryMenu 
            goBack={() => onCancel(true)}
            selectedUrl={content?.imageUrl?.[language] || imagePreview}
            onChange={imageUrl => updateContent({ imageUrl: makeTranslatable(imageUrl) })}
            canvasContainerRef={canvasContainerRef}
            openCropMenu={() => {}}
            scrollableContainerClass={scrollableContainerClass}
            editor={editor}
            component={previewComponent}
          />
        </>
      }
      {addBlockCurrentStep.name === "product" &&
        <ProductMenu 
          onChange={updateContent}
          content={content}
          // selectedProductId={content?.product?.id_product}
          editor={editor}
          forceSelectProduct={true}                            
        />
      }
      {addBlockCurrentStep.name === "textProduct" &&
        <GeneratedTextProductMenu 
          content={content}
          onChange={updateContent}
          language={language}
          block={blocks.find(block => block.getId() === (formBlockId || defaultBlockId) )}
          onAddBlock={onAddBlock}
        />
      }
      {addBlockCurrentStep.name === "text" &&
        <>
          <h2 className={classes.stepTitle}><Translation id="newsletter.rightMenu.content.text.title" /></h2>
          <GeneratedTextMenu 
            language={language}
            block={blocks.find(block => block.getId() === (formBlockId || defaultBlockId) )}
            onChange={updateContent}
            selectedWordings={content}
          />
        </>
      }
      {addBlockCurrentStep.name === "promoCode" &&
        <>
          <h2 className={classes.stepTitle}><Translation id={`newsletter.rightMenu.content.${category}.title`} /></h2>
          <div className={classes.separator} />
          <div className={!content.promoCode ? classes.paddingTop20 : null}>
            <PromoCodeOptionalMenu 
              templateId={templateId} 
              updateContent={updateContent}
              content={content}
              block={blocks.find(block => block.getId() === (formBlockId || defaultBlockId) )}
              editor={editor}
              onNext={onNext}
              currentStep={addBlockCurrentStep}
            ></PromoCodeOptionalMenu>
          </div>
        </>
      }
      {addBlockCurrentStep.name === "recoproduct" &&
        <RecoProductMenu 
          onChange={updateContent}
          content={content}
        />
      }

      {!addBlockCurrentStep.customFooter &&
        <div className={classes.footerContainer} style={{}}>
          <div className={classes.footer}>
            { stepIdx > 0 ?
              <CustomButton
                type="secondary"
                size="sm"
                onClick={() => setStepIdx(prev => prev - 1)}
              >
                <Translation id="button.previous" />
              </CustomButton> :
              <CustomButton
                type="secondary"
                size="sm"
                onClick={() => onCancel(true)}
              >
                <Translation id="button.anulation" />
              </CustomButton>
            }

            <CustomButton
              type="primary"
              size="sm"
              onClick={
                (isContentOk || steps.findLastIndex(elem => elem.type === "content") === stepIdx) && (isFormOk || formStepViewed) ?
                  () => onFinish() : onNext
              }
              disabled={!isStepOk}
            >
              <Translation id={
                (isContentOk || steps.findLastIndex(elem => elem.type === "content") === stepIdx) && (isFormOk || formStepViewed) ?
                  "button.finish" : "button.next2"} />
            </CustomButton>
          </div>
        </div>
      }

      {addBlockCurrentStep.customFooter && addBlockCurrentStep.name === "image" &&
        <div className={`${classes.footerContainer} ${classes.customFooterImageContainer}`}>
          <div className={classes.footer}>
            <CustomButton
              type="primary"
              size="sm"
              disabled={!isStepOk}
              onClick={onNext}
            >
              <Translation id="rightMenuAddBlock.customFooterImage.button" />
            </CustomButton>
          </div>
        </div>
      }
    </div>
  );
};