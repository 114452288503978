
import { useIntl } from "react-intl";
import { useEffect, useState, useContext } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import { CustomButton } from "../../common/Button/CustomButton";
import { Translation } from "../../common/Translation";
import { Dialog } from "../../common/Dialog";
import { AccountContext } from "../../../contexts/context";
import { EventSystem } from "../../../eventsystem/EventSystem.js";

import classes from "./ShopifyImportLogoPopup.module.scss";

export const ShopifyImportLogoPopup = () => {

  const intl = useIntl();
  const { account } = useContext(AccountContext);

  const [logoSuggestion, setLogoSuggestion] = useState();
  const [logoSuggestionIsLoading, setLogoSuggestionIsLoading] = useState();
  const [shouldShowShopifyImportLogo, setShouldShowShopifyImportLogo] = useState(false);

  const suggestShopifyLogo = async (shopUrl) => {
    const environment = process.env.REACT_APP_ENV === "production" ? "production" : "preprod";
    try {
      const { url, data, extension } = await fetch(`https://logo-manager.${environment}.kiliba.eu/suggestLogo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          shopUrl
        })
      }).then(response => response.json());

      const logoSuggestion = url && data && extension ? {
        url,
        data,
        extension
      } : null;

      setLogoSuggestion(logoSuggestion);
      setLogoSuggestionIsLoading(false);
    } catch (error) {
      setLogoSuggestion(null);
      setLogoSuggestionIsLoading(false);
    }
  };

  useEffect(() => {

    if (!account) {
      return;
    }

    (async () => {

      const hasCms = account.step_register > 2 || !!account.shopify;

      if (localStorage.getItem("connectionType") === "fromShopifyModule") {
        await suggestShopifyLogo(account.shopify.session.shopUrl);
      }

      if (hasCms && account.CMS === "shopify" && !account?.url_logo) {
        setShouldShowShopifyImportLogo(true);
        setLogoSuggestionIsLoading(false);
      }

    })();

  }, [account]);

  const redirectToCustomMail = async (accepts = false) => {
    if (accepts && logoSuggestion?.url && logoSuggestion?.data) {
      sessionStorage.setItem("shopifyLogoUrl", logoSuggestion.url);
      sessionStorage.setItem("shopifyLogoExtension", logoSuggestion.extension);
      sessionStorage.setItem("shopifyLogoBuffer", logoSuggestion.data);
    }

    EventSystem.publish("url_switch", "/template_customization");
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: logoSuggestion ? "60%" :
            logoSuggestionIsLoading ? "40%" : "unset",
          height: logoSuggestionIsLoading ? "40%" : "unset"
        }
      }}
      isOpen={shouldShowShopifyImportLogo}
      closeStyle={null}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {logoSuggestionIsLoading ? (
          <>
            <div
              className={classes.logoSuggestionBody}
            >
              <CircularProgress size={26} className={classes.loading} />
              <Translation id="home.shopifyImportLogoDialog.loading_1" />
              <Translation id="home.shopifyImportLogoDialog.loading_2" />
            </div>
          </>
        ) : (
          <>
            <h2>{intl.messages["home.shopifyImportLogoDialog.title"]}</h2>
            <div
              className={classes.shopifyCmsCelebrationDialogDescription}
            >
              {logoSuggestion ? (
                <div className={classes.logoSuggestionBody}>
                  <img
                    className={classes.logoPickerImage}
                    alt={
                      intl.messages[
                        "templateCustomization.logoParameters.unableToLoad"
                      ]
                    }
                    src={logoSuggestion.url}
                  />
                  <Translation id="home.shopifyImportLogoDialog.logo_found" />
                  <Translation id="home.shopifyImportLogoDialog.logo_found_2" />
                </div >
              ) : <Translation id="home.shopifyImportLogoDialog.text" />}
            </div >
            <div>
              {logoSuggestion ? (
                <span className={classes.shopifyLogoSuggestionButtons}>
                  <CustomButton
                    onClick={() => redirectToCustomMail()}
                    type={"secondary"}
                  >
                    <Translation id="home.shopifyImportLogoDialog.secondary_button" />
                  </CustomButton>
                </span>
              ) : null}
              <span className={classes.shopifyLogoSuggestionButtons}>
                <CustomButton
                  onClick={() => redirectToCustomMail(true)}
                  type={"primary"}
                >
                  {logoSuggestion ? (
                    <Translation id="home.shopifyImportLogoDialog.logo_found_primary_button" />
                  ) : <Translation id="home.shopifyImportLogoDialog.primary_button" />}
                </CustomButton>
              </span>
            </div>
          </>)}
      </div>
    </Dialog>
  );
};