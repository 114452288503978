
import { useEffect, useState, useContext, useCallback } from "react";
import { useIntl } from "react-intl";

import { Dialog } from "../../common/Dialog";
import { Translation } from "../../common/Translation";
import { CustomButton } from "../../common/Button/CustomButton";
import { AccountContext } from "../../../contexts/context";
import { EventSystem } from "../../../eventsystem/EventSystem.js";

import classes from "./ShopifySubscriptionPopup.module.scss";
import { useAuth } from "../../../hooks/useAuth";

export const ShopifySubscriptionPopup = () => {

  const intl = useIntl();
  const { account } = useContext(AccountContext);
  const auth = useAuth();

  const [subscriptionIsLoading, setSubscriptionIsLoading] = useState(true);
  const [shouldShowShopifySubscription, setShouldShowShopifySubscription] = useState(false);
  const [subscriptionFailed, setSubscriptionFailed] = useState(false);

  const updateAccountBilling = useCallback(async () => {
    const response = await auth.fetch("/api/payment/updateAccountBilling", {
      method: "PATCH"
    }).then(response => response).catch(e => {});
    return response;
  }, [auth]);

  useEffect(() => {

    if (!account) {
      return;
    }

    // Needed to request back with the right CMS (since shopify)
    localStorage.setItem("CMS", account.CMS);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Shopify subscription handle
    if (
      account.owner !== "Client Payant" &&
      urlParams.get("charge_id") &&
      urlParams.get("charge_id") !== ""
    ) {
      setShouldShowShopifySubscription(true);

      // check if account is activated
      let subscriptionCheckTries = 0;
      const subscriptionCheck = setInterval(async () => {
        const result = await updateAccountBilling();

        const NB_RETRY = 5;

        subscriptionCheckTries++;
        if (result?.success || subscriptionCheckTries > NB_RETRY) {
          clearInterval(subscriptionCheck);
          setSubscriptionIsLoading(false);
          setSubscriptionFailed(subscriptionCheckTries > NB_RETRY);
        }
      }, 3000);

      return () => clearInterval(subscriptionCheck);
    }
  }, [account, updateAccountBilling]);

  const openZendesk = () => {
    setShouldShowShopifySubscription(false);
    window.zE("messenger", "open");
  };

  const closeShopifySubscriptionModal = () => {
    setShouldShowShopifySubscription(false);
    EventSystem.publish("hide_subscription_bar");
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 25,
          display: "flex",
          width: subscriptionIsLoading ? "560px" : "unset",
          height: subscriptionIsLoading ? "500px" : "unset"
        }
      }}
      isOpen={shouldShowShopifySubscription}
      shouldShowCloseButton={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {subscriptionIsLoading ? (
          <>
            <div className={classes.logoSuggestionBody} >
              <div className={classes.popupText} >
                <Translation id="home.shopifySubscriptionDialog.loading1" /><br></br>
                <Translation id="home.shopifySubscriptionDialog.loading2" />
              </div>
              <div className={classes.popupGifContentMask}></div>
              <div className={classes.popupGifContent}>
                <img alt="" className={classes.giphy} src="https://production-kiliba-assets.s3.eu-central-1.amazonaws.com/kiliba/gifs/cat-computer-working.gif"></img>
              </div>
            </div>
          </>
        ) : (
          <>
            <h2 className={classes.subscriptionTitle}>
              {subscriptionFailed ?
                intl.messages["home.shopifySubscriptionDialog.error"] :
                intl.messages["home.shopifySubscriptionDialog.title"]
              }
            </h2>
            {
              subscriptionFailed ? (
                <CustomButton
                  onClick={() => openZendesk()}
                  type={"primary"}
                >
                  <Translation id="home.shopifySubscriptionDialog.error_button" />
                </CustomButton>
              ) :
                <CustomButton
                  onClick={() => closeShopifySubscriptionModal()}
                  type={"primary"}
                >
                  <Translation id="home.shopifySubscriptionDialog.button" />
                </CustomButton>
            }
          </>)}
      </div>
    </Dialog>
  );
};