import { getPriceFromProduct, getTitleTranslation, getDescriptionTranslation } from ".";
import { formatHtmlIntoShortText } from "../../../../../services/text.service";

const LABEL = "product7";
const CATEGORY = "product:card";

export const product7 = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {
    const { product, fontFamily, buttonTextColor, buttonColor, textColor, backgroundColor, imageUrl, imageHref, imageAlt = "", buttonHref, buttonText, productName, priceText, buttonRadius, productDescription, currency, priceIsVisible, currencyBefore, recoType } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    if (!product) {
      return {
        id: LABEL,
        label: LABEL,
        category: CATEGORY,
        activate: false,
        content: "",
      };
    }

    const mjml = `
      <mj-wrapper data-id-product="${product.id_product}" data-gjs="kilibaBlock" background-color="${backgroundColor}" padding-top="0" padding-bottom="0" full-width="full-width" ${recoType ? "css-class=\"productRecoContainer\"" : ""} ${recoType ? `data-reco-type="${recoType}"` : ""}>
        <mj-section direction="rtl">
          <mj-column width="10%"></mj-column>
          <mj-column width="40%" vertical-align="middle">
            <mj-image href="{{imageHref}}" alt="{{imageAlt}}" padding-left="10px" padding-right="10px" css-class="product7-image" src="{{imageUrl}}"></mj-image>
          </mj-column>
          <mj-column width="40%" css-class="product7-info" vertical-align="middle">
            <mj-text font-family="${fontFamily}" padding-left="10px" padding-right="10px" padding-top="0px" padding-bottom="10px" align="center" line-height="16px" color="${textColor}" padding-bottom="10px" font-size="14px" font-weight="700" data-text-id="productName">{{productName}}</mj-text>
            <mj-text font-family="${fontFamily}" padding-left="10px" padding-right="10px" padding-top="0px" align="center" line-height="14px" color="${textColor}" padding-bottom="10px" font-size="12px" data-text-id="productDescription">{{description}}</mj-text>

            <mj-text ${!priceIsVisible ? "css-class=\"priceHidden\"" : "" } data-product-id="${product.id_product}" data-readonly="true" font-family="${fontFamily}" padding-left="10px" padding-right="10px" padding-top="0px" align="center" font-weight="700" line-height="18px" color="${textColor}" padding-bottom="20px" font-size="16px" data-text-id="priceText">
              {{price}}
            </mj-text>

            <mj-button border-radius="${buttonRadius}" href="{{buttonHref}}" font-family="${fontFamily}" padding-left="10px" padding-right="10px" line-height="16px" color="${buttonTextColor}" font-size="14px" padding-top="0px" padding-bottom="0px" inner-padding="8px 15px" background-color="${buttonColor}">
              {{button}}
            </mj-button>
          </mj-column>
          <mj-column width="10%"></mj-column>
        </mj-section> 
      </mj-wrapper>
    `;

    const wordings = {
      button: buttonText || {
        fr: "Acheter",
        en: "Buy",
        es: "¡Comprar!",
        de: "Kaufen",
        nl: "Kopen",
        it: "Acquista",
        pt: "Comprar",
      },
      productName: productName || {
        fr: getTitleTranslation("fr", product.title),
        en: getTitleTranslation("en", product.title),
        es: getTitleTranslation("es", product.title),
        de: getTitleTranslation("de", product.title),
        nl: getTitleTranslation("nl", product.title),
        it: getTitleTranslation("it", product.title),
        pt: getTitleTranslation("pt", product.title),
      },
      price: priceText || {
        fr: getPriceFromProduct(product, currency, currencyBefore),
        en: getPriceFromProduct(product, currency, currencyBefore),
        es: getPriceFromProduct(product, currency, currencyBefore),
        de: getPriceFromProduct(product, currency, currencyBefore),
        nl: getPriceFromProduct(product, currency, currencyBefore),
        it: getPriceFromProduct(product, currency, currencyBefore),
        pt: getPriceFromProduct(product, currency, currencyBefore),
      },
      description: productDescription || {
        fr: formatHtmlIntoShortText(getDescriptionTranslation("fr", product.description)),
        en: formatHtmlIntoShortText(getDescriptionTranslation("en", product.description)),
        es: formatHtmlIntoShortText(getDescriptionTranslation("es", product.description)),
        de: formatHtmlIntoShortText(getDescriptionTranslation("de", product.description)),
        nl: formatHtmlIntoShortText(getDescriptionTranslation("nl", product.description)),
        it: formatHtmlIntoShortText(getDescriptionTranslation("it", product.description)),
        pt: formatHtmlIntoShortText(getDescriptionTranslation("pt", product.description)),
      },
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        variables: {
          imageUrl,
          imageHref,
          imageAlt,
          buttonHref,
        },
        wordings,
        editableProperties: [
          { key: "product", type: "product", label: "", group: "newsletter.rightMenu.propertyGroup.product", properties: [
            { key: "imageUrl", type: "image", label: "newsletter.rightMenu.property.selectedImage", group: "newsletter.rightMenu.propertyGroup.image" },
            { key: "imageHref", type: "link", label: "newsletter.rightMenu.property.imageLink", group: "newsletter.rightMenu.propertyGroup.image" },
            { key: "imageAlt", type: "string", label: "newsletter.rightMenu.property.alt", group: "newsletter.rightMenu.propertyGroup.image" },
            { key: "buttonHref", type: "link", label: "newsletter.rightMenu.property.buttonLink", group: "newsletter.rightMenu.propertyGroup.button" },
            { key: "buttonColor", type: "color", label: "newsletter.rightMenu.property.buttonColor", group: "newsletter.rightMenu.propertyGroup.button" },
            { key: "priceIsVisible", type: "price", label: "", group: "newsletter.rightMenu.property.priceIsVisible" },
          ], keys: ["product", "imageHref", "imageAlt", "imageUrl", "buttonHref", "buttonColor", "buttonText", "productName", "priceText", "productDescription", "priceIsVisible"] },
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ]
      }
    };
  },
  mediaQueryStyle: `
    @media only screen and (min-width:480px) {
      .product7-info td div {
        text-align: left !important;
      }

      .product7-info td {
        float: left;
      }      
    }    
  `,
  style: `
    .product7-image td {
      width: inital !important;
    }
    
    .product7-image img {
      max-width: 200px;
      max-height: 200px;
      margin: 0 auto;
    }

    .priceHidden {
      display: none !important;
      visibility:hidden;
      mso-hide:all;
      font-size:1px;
      line-height:1px;
      max-height:0px;
      max-width:0px;
      opacity:0;
      overflow:hidden;
    }
  `,
});