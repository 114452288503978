import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";

import { AccountContextBehaviorSubject$ } from "../../contexts/context";
import { useAuth } from "../../hooks/useAuth";
import { Translation } from "../common/Translation.js";
import { EventSystem } from "../../eventsystem/EventSystem.js";
import { CustomButton } from "../common/Button/CustomButton";
import { UsecasePopupSalesContact } from "../usecase/Usecase/UsecasePopups/UsecasePopupSalesContact.js";

import { styles } from "../../styles/webmenu/leftmenu.js";

import logo from "../../public/logo-white.png";
import logoWhole from "../../public/logo-whole-white.png";

const useStyles = makeStyles(styles);

const PAGES_DEFAULT_NOT_EXPANDED = ["/editor"];

export function LeftMenu({ onExpansion }) {
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const matchesExpansionBreakpoint = useMediaQuery("(min-width: 1250px)");
  const matchesExpansionBreakpointHeight = useMediaQuery("(max-height: 800px)");
  const classes = useStyles({ small: matchesExpansionBreakpointHeight });

  const [isExpanded, setIsExpanded] = useState(true);
  const [hasSubscribed, setHasSubscribed] = useState(false);
  const [wording, setWording] = useState(null);
  const [quoteNumber, setQuoteNumber] = useState(null);
  const [showModalSalesContact, setShowModalSalesContact] = useState(false);

  const buttonHomeRef = useRef();
  const buttonTemplateCustomizationRef = useRef();
  const buttonUsecasesRef = useRef();
  const buttonContactsRef = useRef();
  const buttonProductsRef = useRef();

  const buttonRefs = useMemo(() => {
    return {
      buttonHome: buttonHomeRef,
      buttonTemplateCustomization: buttonTemplateCustomizationRef,
      buttonUsecases: buttonUsecasesRef,
      buttonContacts: buttonContactsRef,
      buttonProducts: buttonProductsRef,
    };
  }, []);

  // Detect breakpoint change for menu
  useEffect(() => {
    setIsExpanded(matchesExpansionBreakpoint);
  }, [matchesExpansionBreakpoint]);

  // Forward expanded info to parent
  useEffect(() => {
    onExpansion(isExpanded);
  }, [isExpanded, onExpansion]);

  useEffect(() => {
    if (PAGES_DEFAULT_NOT_EXPANDED.find(elem => location.pathname.startsWith(elem))) {
      setIsExpanded(false);
    }
  }, [location]);

  useEffect(() => {
    const { account } = AccountContextBehaviorSubject$.getValue();

    let wordingId;

    switch (account.owner) {
      case "Client Payant": break;
      case "User": wordingId = "header.account_free"; break;
      case "Invité": wordingId = "header.account_guest"; break;
      default: wordingId = "header.account_bloqued"; break;
    }

    EventSystem.subscribe("hide_subscription_bar", () => setHasSubscribed(true));
    EventSystem.subscribe("show_subscription_bar", () => setHasSubscribed(false));
    EventSystem.subscribe("make_appointment", () => setShowModalSalesContact(true));

    setWording(wordingId);
    setHasSubscribed(account.owner === "Client Payant");
    setQuoteNumber(Math.ceil(Math.random() * 20));
  }, [auth]);

  function renderButton(name, idx) {
    const buttonSettingsList = {
      home: { link: "/", iconName: "grid-2" },
      templateCustomization: { link: "/template_customization", iconName: "palette" },
      usecases: { link: "/usecase", iconName: "rectangle-vertical-history", subPages: ["/usecase_details"] },
      newsletter: { link: "/newsletter", iconName: "envelope" },
      contacts: { link: "/customers", iconName: "user-group" },
      products: { link: "/products", iconName: "box-open" },
    };

    const buttonSettings = buttonSettingsList[name];
    const currentPageCorrespondsToButton = window.location.pathname === buttonSettings.link || buttonSettings.subPages?.includes(window.location.pathname);

    return (
      <div className={classes.buttonWrapper} key={idx}>
        <div
          ref={buttonRefs[`button${name[0].toUpperCase()}${name.slice(1, Infinity)}`]}
          className={`${classes.button} ${currentPageCorrespondsToButton ? classes.buttonSelected : ""}`}
          onClick={() => history.push(buttonSettings.link)}
        >
          <i className={`fa-solid fa-${buttonSettings.iconName} ${classes.icon}`}></i>
          {
            ( !isExpanded && name === "newsletter") &&  <span className={classes.iconStar}>✨</span>
          }
          <p className={`textBold ${classes.buttonLabel} ${isExpanded ? classes.buttonLabelExpanded : ""}`}>
            <Translation id={`leftMenu.${name}`} />
          </p>  
        </div>
        <div className={`textMedium ${classes.tooltip}`}>
          <Translation id={`leftMenu.tooltip.${name}`} />
        </div>
      </div>
    );
  }

  const buttons = [
    "home",
    "templateCustomization",
    "usecases",
    "newsletter",
    "contacts",
    "products",
  ].map(renderButton);

  useEffect(() => {
    // make icons 25px max in width and height
    function resizeButtonIcons() {
      Object.keys(buttonRefs).forEach(buttonKey => {
        const buttonRef = buttonRefs[buttonKey];
        if (!buttonRef.current) return;
        const icon = buttonRef.current.querySelector("i");
        const requiredIconWidth = 25;
        const iconWidth = Number(getComputedStyle(icon, "::before").width.replace("px", ""));
        // since the icons are characters, the height is already capped with the
        // font-size, therefore only the width has to be manually capped
        if (iconWidth > 25) {
          const scaleRatioToApply = requiredIconWidth / iconWidth;

          icon.style.setProperty("--scale-ratio", scaleRatioToApply);
        }
      });
    }

    document.fonts.ready.then(resizeButtonIcons);
  }, [buttonRefs]);

  return (
    <div className={`${classes.leftMenu} ${isExpanded ? classes.expanded : ""}`}>
      <div className={classes.topPart}>
        <img className={classes.logo} src={isExpanded ? logoWhole : logo} alt="logo" onClick={() => history.push("/")} />
        <div className={classes.buttons}>
          {buttons}
        </div>
        {
          matchesExpansionBreakpoint
            ? (
              <div className={classes.expansionTagVisibleZone}>
                <div className={classes.expansionTagExtendedExpansionZone}>
                  <div className={classes.expansionTag} onClick={() => setIsExpanded(expanded => !expanded)}>
                    <i className={`fa-solid fa-chevron-${isExpanded ? "left" : "right"}`} />
                    <i className={`fa-solid fa-chevron-${isExpanded ? "left" : "right"}`} />
                  </div>
                </div>
              </div>
            )
            : null
        }
      </div>

      <div className={classes.bottomPart}>
        {!hasSubscribed && window.location.pathname !== "/subscription" &&
          <>
            {isExpanded && wording && <div className={classes.wording}>
              <Translation id={wording} />
            </div>}

            <div className={!isExpanded ? classes.bottomButtonNotExpanded : ""}>
              <CustomButton
                type="primary"
                size={"md"}
                style={{ width: "100%", zIndex:"1" }}
                onClick={() => setShowModalSalesContact(true)}
              >
                <Translation
                  id="usecase.popupContact.btnContactRdv"
                  defaultMessage="LetsGo"
                />
              </CustomButton>
            </div>
          </>
        }

        {!hasSubscribed && showModalSalesContact ? (
          <UsecasePopupSalesContact
            variant="topbar"
            closeSalesContactPopup={() => setShowModalSalesContact(false)}
          ></UsecasePopupSalesContact>
        ) : null}

        {hasSubscribed && isExpanded && quoteNumber &&
          <div className={classes.quote}>
            <Translation id={`leftmenu.quote${quoteNumber}`} />
          </div>
        }

      </div>
    </div>
  );
}
