import { useMemo } from "react";
import { useIntl } from "react-intl";

import { KiDropdown } from "../common/Input/KiDropdown";
import { Translation } from "../common/Translation";
import { KiCheckbox } from "../common/KiCheckbox/KiCheckbox";
import { palette } from "../../styles/palette";

import classes from "./UsecasePicker.module.scss";

const CATEGORY_COLOR = {
  "conversion": "#FFC5AC",
  "trafic": "#D4F1AF",
  "reactivation": "#FCE",
  "events": "#D1D3FF",
  "customs": "#DDDAD5",
  "newsletter": "#FFE7B9"
};

export const UsecasePicker = ({ usecases, onUsecaseClick, onUsecaseCategoryClick }) => {

  const intl = useIntl();

  const sortedUsecasesByCategory = useMemo(() => {
    const usecaseCategories = {
      all: [
        {
          key: "all",
          wording: intl.messages["usecase.allUsecases"],
        },
      ],
    };

    usecases?.forEach(usecase => {
      if (!usecaseCategories[usecase.category]) usecaseCategories[usecase.category] = [];

      usecaseCategories[usecase.category].push({
        key: usecase.name,
        wording: intl.messages[`usecasePicker.${usecase.name}`],
        checked: usecase.checked,
      });
    });

    for (const categoryKey in usecaseCategories) {
      /* sort alphabeticaly */
      usecaseCategories[categoryKey].sort(function (a, b) {
        return a.wording.localeCompare(b.wording, intl.locale, {
          sensitivity: "base",
        });
      });
    }

    return usecaseCategories;

  }, [intl, usecases]);

  const dropdownLabel = useMemo(() => {
    const checkedUsecases = usecases && usecases.filter(usecase => usecase.checked);

    let title;

    if (!checkedUsecases || checkedUsecases.length === 0) {
      title = intl.messages["usecasePicker.noUsecase"];
    } else if (checkedUsecases.length === 1) {
      title = intl.messages["usecase." + checkedUsecases[0].name];
    } else if (checkedUsecases.length === usecases.length) {
      title = intl.messages["usecasePicker.allUsecases"];
    } else {
      title = intl.messages["usecasePicker.multipleUsecases"];
    }

    return title;

  }, [usecases, intl]);

  const allUsecasesAreChecked = useMemo(() => Boolean(usecases && !usecases.find(usecase => !usecase.checked)), [usecases]);

  return (
    <KiDropdown
      label={dropdownLabel}
      variant="table"
      placement={"bottom-start"}
      flipIsEnabled={false}
    >
      <div className={classes.container}>
        <div className={classes.allContainer}>
          <KiCheckbox
            checked={allUsecasesAreChecked}
            label={intl.messages["usecase.allUsecases"]}
            onChange={() => onUsecaseClick("all")}
          />
        </div>
        <div className={classes.categoriesContainer}>
          {["conversion", "trafic", "reactivation", "events", "customs", "newsletter"].map(category => (

            <div className={`${classes.categoryContainer} ${classes[category]}`}>
              <div className={`textMedium ${classes.categoryTitle}`}>
                {sortedUsecasesByCategory[category] && 
                  <KiCheckbox 
                    checked={sortedUsecasesByCategory[category].every(usecase => usecase.checked)}
                    onChange={() => onUsecaseCategoryClick(category)}
                  />
                }
                <Translation id={`usecases.${category}.title`} />
              </div>
              <div className={classes.usecasesContainer}>
                {sortedUsecasesByCategory[category] && sortedUsecasesByCategory[category].map(usecase => (
                  <KiCheckbox
                    checked={usecase.checked}
                    label={usecase.wording}
                    onChange={() => onUsecaseClick(usecase.key)}
                    backgroundColor={CATEGORY_COLOR[category]}
                    iconColor={palette.black}
                  />
                ))}
              </div>            
            </div>

          ))}
        </div>
      </div>
    </KiDropdown>
  );
};